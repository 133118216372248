import Partners from "../pages/home/components/partners";
import Footer from "./footer";
import Navbar from "./navbar";

export default function Layout({ children }: any) {
    return (
        <>
            <Navbar />
            {children}
            <Partners />
            <Footer />
        </>
    )
}