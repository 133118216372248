import React, { useEffect, useState } from 'react';

import Faqs from '../content/images/icons/support.png';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import Loading from '../components/loading';

export default function Contact() {

    const [faqsList, setFaqsList] = useState<any>(null);
    const [emailGeral, setEmailGeral] = useState("");

    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        async function fetchData() {
            try {
                let docRef = doc(db, 'website', 'contact');
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setFaqsList(data.faqs);
                    setEmailGeral(data.email);
                }
            } catch (error) {
                console.error("Error getting document: ", error);
            }
        }
        fetchData();
    }, []);

    async function submit() {
        if (!email || email === "" || !email.match(/^[^ ]+@[^ ]+\.[a-z]{2,3}$/)) {
            await alert("Por favor, insira um e-mail válido.");
            return;
        }

        if (!subject || subject === "") {
            await alert("Por favor, insira um assunto.");
            return;
        }

        if (!message || message === "") {
            await alert("Por favor, insira uma mensagem.");
            return;
        }

        // const emailParams = { email: email, subject: subject, message: message };

        // TODO: send email
    }

    if (!faqsList || emailGeral === '') {
        return (
            <Loading />
        )
    }

    return (
        <div className='pt-[8rem] flex flex-col min-h-screen gap-[3rem] pb-[5rem] px-[8vw]'>
            <div>
                <h1 className='font-garetBold text-[2rem] text-center'>Contacto</h1>
                <h2 className='text-[1.2rem] seis:text-[1.5rem] text-center'>Em que podemos ajudá-lo?</h2>
            </div>
            <div className='bg-[var(--white)] w-full px-8 py-3 rounded-[20px] flex seis:flex-row flex-col justify-between items-center gap-4 mb-[2rem]'>
                <div>
                    <p className='font-garetBold'>Quer contactar-nos?</p>
                    <p className=''>De segunda a sexta-feira, das 9:00 às 18:00 CET</p>
                </div>
                <div className='flex gap-2 mil:gap-6 mil:flex-row flex-col'>
                    <button className='w-[12rem] font-garetBold text-white rounded-full px-6 py-2 bg-[var(--primary)] text-center'>LIVE Chat</button>
                    <button className='w-[12rem] font-garetBold text-white rounded-full px-6 py-2 bg-[var(--primary)] text-center'><a href={`mailto:${emailGeral}`} className='font-garetBold'>{emailGeral}</a></button>
                </div>
            </div>
            <div className="flex mil:flex-row flex-col-reverse justify-between gap-[10vw]">
                <div className="w-full  space-y-4 text-[1rem]">
                    <div className="flex items-center gap-4">
                        <label htmlFor="email" className="w-1/3 font-garetBold">
                            Email:
                        </label>
                        <input
                            type="email"
                            name="email"
                            placeholder="|"
                            value={email}
                            className="w-2/3 form-input bg-[transparent] px-4 py-[5px] sete:py-2 border-2 rounded-full border-black"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="flex items-center gap-4">
                        <label htmlFor="subject" className="w-1/3 font-garetBold">
                            Assunto
                        </label>
                        <input
                            type="text"
                            name="subject"
                            placeholder="|"
                            value={subject}
                            className="w-2/3 form-input bg-[transparent] px-4 py-[5px] sete:py-2 border-2 rounded-full border-black"
                            onChange={(e) => setSubject(e.target.value)}
                        />
                    </div>
                    <div className="flex items-start gap-4">
                        <label htmlFor="message" className="w-1/3 font-garetBold">
                            Mensagem
                        </label>
                        <textarea
                            name="message"
                            placeholder="|"
                            value={message}
                            rows={10}
                            cols={10}
                            className="resize-none w-2/3 form-input bg-[transparent] px-4 py-[5px] sete:py-2 border-2 rounded-[20px] border-black"
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                    <div className="text-center flex gap-4">
                        <button
                            onClick={submit}
                            className="w-full py-1 oito:py-3 bg-[var(--primary)] text-white rounded-full"
                        >
                            Enviar
                        </button>
                    </div>
                </div>
                <div className="w-full flex flex-col gap-8">
                    <div className='flex gap-10 items-center'>
                        <p className='font-garetBold text-[1.5rem]'>FAQ's</p>
                        <img src={Faqs} alt="icon" className='w-16' />
                    </div>
                    <div className="h-[20rem] overflow-y-auto">
                        <div className='flex flex-col gap-4'>
                            {
                                faqsList && faqsList.length !== 0 ? faqsList.map((ele: any, index: number) => {
                                    return (
                                        <div key={index} className='flex flex-col gap-2'>
                                            <p className='font-garetBold'>{ele?.questao}</p>
                                            <p>{ele?.resposta}</p>
                                        </div>
                                    )
                                }) :
                                    <p className='text-left w-full'>Nenhum resultado</p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

