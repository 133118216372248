import React, { useState } from 'react';
import Input from '../components/input';
import Select from '../components/select';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

export default function Signup() {
    const [type, setType] = useState('user');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [city, setCity] = useState('');
    const [prefixo, setPrefixo] = useState('');
    const [contact, setContact] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companySize, setCompanySize] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [terms, setTerms] = useState(false);
    const [newsletter, setNewsletter] = useState(false);

    const sizeOptions = ['1-5', '2-10', '10-50', '50+']

    const signup = async () => {
        if (name === '' || lastName === '' || city === '' 
            || prefixo === '' || contact === '' || companyName === ''
            || companySize === '' || email === '' || password === '') {
            alert('Preencha todos os campos.')
            return ;
        }
        if (!terms) {
            alert('Para se registar deve aceitar os termos e condições.')
            return ;
        }
        await signInWithEmailAndPassword(auth, email, password)
        .then(() => {
            localStorage.setItem('email', email);
            window.location.href = '/';
        })
        .catch(() => {
            alert('Email ou password incorretos.')
            return ;
        });
    }

    return (
        <div className='pt-[10rem] flex flex-col gap-[3rem] pb-[10rem]'>
            <h1 className='text-[1.2rem] quatro:text-[2rem] text-center'>Bem-vindo à <span className='font-garetBold uppercase'>Multipark</span></h1>
            <div className=' flex flex-col items-center gap-8 w-full'>
                <div className='max-w-[40rem] flex flex-col items-center gap-8 w-full'>
                    <div className='w-full flex flex-col gap-8'>
                        <p>Tipo de conta:</p>
                        <div className='relative w-full border-2 py-4 border-black rounded-full h-8 flex justify-evenly items-center'>
                            <div className={`absolute top-0 ${type === 'company' ? 'left-1/2' : 'left-0'} h-full w-1/2 bg-[var(--primary)] rounded-full z-[-1] transition-all duration-300`}></div>
                            <p onClick={() => setType('user')} className={`w-1/3 text-[.8rem] text-center cursor-pointer ${type === 'company' ? 'text-black' : 'text-white'}`}>Pessoal</p>
                            <p onClick={() => setType('company')} className={`w-1/3 text-[.8rem] text-center cursor-pointer ${type !== 'company' ? 'text-black' : 'text-white'}`}>Empresa</p>
                        </div>
                        <div className='w-full flex seis:flex-row flex-col gap-8 seis:gap-[5vw]'>
                            <Input label='Nome' value={name} setValue={setName} />
                            <Input label='Último nome' value={lastName} setValue={setLastName} />
                        </div>
                        <div className='w-full flex seis:flex-row flex-col gap-8 seis:gap-[5vw]'>
                            <Input label='Cidade de residência' value={city} setValue={setCity} />
                        </div>
                        <div className='w-full flex seis:flex-row flex-col gap-8 seis:gap-[5vw]'>
                            <Input label='Prefixo' value={prefixo} setValue={setPrefixo} />
                            <Input label='Telefone' value={contact} setValue={setContact} />
                        </div>
                        {
                            type === 'company' &&
                            <div className='w-full flex seis:flex-row flex-col gap-8 seis:gap-[5vw]'>
                                <Input label='Nome da empresa' value={companyName} setValue={setCompanyName} />
                                <Select label='Tamanho da empresa' value={companySize} setValue={setCompanySize} list={sizeOptions} />
                            </div>
                        }
                        <div className='w-full flex seis:flex-row flex-col gap-8 seis:gap-[5vw]'>
                            <Input label='Email' value={email} setValue={setEmail} type="email" />
                        </div>
                        <div className='w-full flex seis:flex-row flex-col gap-8 seis:gap-[5vw]'>
                            <Input label='Password' value={password} setValue={setPassword} type="password" />
                        </div>
                        <div className="flex flex-col gap-8">
                            <div className='flex items-center gap-3'>
                                <div onClick={() => setTerms(!terms)} className={`w-6 h-6 shrink-0 border-black border-2 rounded-[5px] cursor-pointer ${terms ? 'bg-[var(--primary)]' : 'bg-[var(--white)]'}`}></div>
                                <p className='uppercase font-garetBold p-4 w-[17rem] quatro:w-[20rem] text-balance'>Aceito os Termos e Condições</p>
                            </div>
                            <div className='flex items-center gap-3'>
                                <div onClick={() => setNewsletter(!newsletter)} className={`w-6 h-6 shrink-0 border-black border-2 rounded-[5px] cursor-pointer ${newsletter ? 'bg-[var(--primary)]' : 'bg-[var(--white)]'}`}></div>
                                <p className='uppercase font-garetBold p-4 w-[17rem] quatro:w-[20rem] text-balance'>Quero manter-me a par das novidades da <span className='uppercase font-garetBold'>Multipark</span>.</p>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex flex-col justify-center items-center gap-8'>
                        <button onClick={signup} className='w-full rounded-full bg-[var(--primary)] px-4 py-2 text-white'>Registar</button>
                        <p className='text-center text-[.8rem] text-balance'>Tem conta na <span className='uppercase font-garetBold'>Multipark</span>? Inicie sessão <a href="/login" className='underline'>aqui</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

