import React, { useState } from 'react';
import Input from '../../../components/input';
import Select from '../../../components/select';
import UploadIcon from '../../../content/images/icons/upload.png';
import { addDoc, collection } from 'firebase/firestore';
import { db, storage } from '../../../firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import axios from 'axios';

export default function Form({ ramoOptions, tipoOptions }:{ramoOptions:any, tipoOptions:any}) {

    const [ramo, setRamo] = useState('');
    const [nomeEmpresa, setNomeEmpresa] = useState('');
    const [nomeComercial, setNomeComercial] = useState('');
    const [emailEmpresa, setEmailEmpresa] = useState('');
    const [nomeAssinatura, setNomeAssinatura] = useState('');
    const [cargoAssinatura, setCargoAssinatura] = useState('');
    const [contactoEmpresa, setContactoEmpresa] = useState('');
    const [morada, setMorada] = useState('');
    const [nif, setNif] = useState('');
    const [link, setLink] = useState('');
    const [dias, setDias] = useState('');
    const [horas, setHoras] = useState('');
    const [tipo, setTipo] = useState('');
    const [logo, setLogo] = useState<any>(null);
    const [nomePessoal, setNomePessoal] = useState('');
    const [emailPessoal, setEmailPessoal] = useState('');
    const [contactoPessoal, setContactoPessoal] = useState('');
    const [cargoPessoal, setCargoPessoal] = useState('');


    const diasOptions = Array.from({ length: 31 }, (_, index) => index + 1);
    const horasOptions = Array.from({ length: 24 }, (_, index) => index + 1);

    const handleFileChange = () => (event: any) => {
        const file = event.target.files[0];
        if (file && (file.name.endsWith('.png') || file.name.endsWith('.jpg'))) {
            setLogo(file);
        } else {
            alert('Please upload a file with the .pdf format.');
        }
    };

    
    async function submit() {
    if (!ramo || !nomeEmpresa || !nomeComercial || !emailEmpresa ||
        !nomeAssinatura || !cargoAssinatura || !contactoEmpresa ||
        !morada || !nif || !link || dias === '' ||
        horas === '' || !tipo || !nomePessoal ||
        !emailPessoal || !contactoPessoal || !cargoPessoal) {
        return alert('Preencha todos os campos.');
    }

    const jsonData = {
        ramo: ramo,
        nomeEmpresa: nomeEmpresa,
        nomeComercial: nomeComercial,
        emailEmpresa: emailEmpresa,
        nomeAssinatura: nomeAssinatura,
        cargoAssinatura: cargoAssinatura,
        contactoEmpresa: contactoEmpresa,
        morada: morada,
        nif: nif,
        link: link,
        dias: dias,
        horas: horas,
        tipo: tipo,
        logo: logo,
        nomePessoal: nomePessoal,
        emailPessoal: emailPessoal,
        contactoPessoal: contactoPessoal,
        cargoPessoal: cargoPessoal
    };
    const url = `${process.env.REACT_APP_API_URL}/api/v1/partners/create`

    try {
        const response = await axios.post(url, jsonData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.data.condition === "success") {
            alert('Dados guardados com sucesso, entraremos em contacto em breve.');
            window.location.reload();}
        
    } catch (error) {
        console.error('Error saving data:', error);
        alert('Erro ao salvar dados.');
    }
}

    return (
        <div className='flex flex-col seis:px-[7vw] pb-[10rem]'>
            <h1 className='text-[1.5rem] quatro:text-[2rem] font-garetBold text-center my-[4rem]'>Become a Partner</h1>
            <p className='font-garetBold uppercase text-[1.2rem] quatro:text-[1.5rem] mb-10'>Dados do novo parceiro</p>
            <div className='grid oito:grid-cols-2 grid-cols-1 oito:gap-[7vw] gap-8 mb-[5rem]'>
                <div className='flex flex-col gap-8'>
                    <Select label="ramos de atividade" value={ramo} setValue={setRamo} list={ramoOptions} />
                    <Input label="nome da empresa" value={nomeEmpresa} setValue={setNomeEmpresa} />
                    <Input label="nome comercial" value={nomeComercial} setValue={setNomeComercial} />
                    <Input label="email geral" value={emailEmpresa} setValue={setEmailEmpresa} />
                    <Input label="contacto telefónico geral" value={contactoEmpresa} setValue={setContactoEmpresa} />
                    <Input label="nome da pessoa que vai assinar o acordo de parceria" value={nomeAssinatura} setValue={setNomeAssinatura} />
                    <Input label="cargo com poderes para o ato" value={cargoAssinatura} setValue={setCargoAssinatura} />
                </div>
                <div className='flex flex-col gap-8'>
                    <Input label="morada completa" value={morada} setValue={setMorada} />
                    <Input label="NIF/Nº contribuinte" value={nif} setValue={setNif} />
                    <Input label="página web (link do site)" value={link} setValue={setLink} />
                    <div className='flex flex-col gap-2'>
                        <p className='uppercase font-garetBold pl-4'>Horário de funcionamento</p>
                        <div className='flex gap-8'>
                            <select
                                value={dias}
                                onChange={(e) => setDias(e.target.value)}
                                className='w-full bg-[var(--white)] px-4 py-2 rounded-full outline-[var(--primary)]'
                            >
                                <option value="">Dias</option>
                                {
                                    diasOptions.length !== 0 ? diasOptions.map((ele: number, index: number) => {
                                        return (
                                            <option key={index} className='bg-[var(--white)] px-6 py-2 rounded-full outline-[var(--primary)]'>
                                                {ele}
                                            </option>
                                        )
                                    }) :
                                        <p className='text-center w-full'>Nenhum resultado</p>
                                }
                            </select>
                            <select
                                value={horas}
                                onChange={(e) => setHoras(e.target.value)}
                                className='w-full bg-[var(--white)] px-4 py-2 rounded-full outline-[var(--primary)]'
                            >
                                <option value="">Horas</option>
                                {
                                    horasOptions.length !== 0 ? horasOptions.map((ele: number, index: number) => {
                                        return (
                                            <option key={index} className='bg-[var(--white)] px-6 py-2 rounded-full outline-[var(--primary)]'>
                                                {ele}
                                            </option>
                                        )
                                    }) :
                                        <p className='text-center w-full'>Nenhum resultado</p>
                                }
                            </select>
                        </div>
                    </div>
                    <Select label="tipo" value={tipo} setValue={setTipo} list={tipoOptions} />
                    <div className='flex flex-col gap-8'>
                        <p className='font-garetBold uppercase'>Opcional:</p>
                        <div className="flex gap-10 items-center">
                            <p className='uppercase font-garetBold w-[10rem]'>Logotipo da empresa/pessoa</p>
                            <div className='relative w-[10rem] text-white rounded-full h-[2.5rem] flex justify-center items-center cursor-pointer'>
                                <input type="file" onChange={(event) => handleFileChange()(event)} className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer' />
                                <button className='w-10 h-10 text-white rounded-[7px] bg-[var(--primary)] flex justify-center items-center gap-2'><img src={UploadIcon} alt="icon" className='w-6' /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h3 className='text-[1.2rem] oito:text-[1.5rem] w-full oito:w-2/3 text-balance'><span className='font-garetBold'>Dados de contacto</span> (pessoa responsável pelo acompanhamento da parceria com a multipark)</h3>
            <div className='grid oito:grid-cols-2 grid-cols-1 oito:gap-[7vw] gap-8 my-[5rem]'>
                <div className='flex flex-col gap-8'>
                    <Input label="name" value={nomePessoal} setValue={setNomePessoal} />
                    <Input label="email" value={emailPessoal} setValue={setEmailPessoal} />
                </div>
                <div className='flex flex-col gap-8'>
                    <Input label="contacto (telemóvel)" value={contactoPessoal} setValue={setContactoPessoal} />
                    <Input label="cargo" value={cargoPessoal} setValue={setCargoPessoal} />
                </div>
            </div>
            <div className='w-full flex flex-col items-center mb-[5rem]'>
                <button onClick={submit} className='p-2 bg-[var(--primary)] text-white rounded-[7px] mb-10'>Ser Parceiro</button>
                <p className='font-garetBold text-center w-full mil:w-1/2'>IRÁ RECEBER UM E-MAIL COM O CONTRATO E AS INFORMAÇÕES DE ACESSO À SUA DASHBOARD ONDE PODE CONTROLAR: DINHEIRO, RESERVAS E EDIÇÕES</p>
            </div>
            <p className='uppercase text-[1.2rem] oito:text-[1.5rem]'><span className='font-garetBold'>Nota:</span> Qualquer questão envie para <a href="mailto:partners@multipark.pt" className='font-garetBold hover:underline'>partners@multipark.pt</a></p>
        </div>
    );
}

