import { useEffect, useState } from 'react';

import Logo from '../content/images/logos/logo.png';
import ArrowDown from '../content/images/icons/down.png';
import HowItWorks from './howItWorks';

export default function Navbar() {

    // const moreMenuRef = useRef();
    const [isAuth, setIsAuth] = useState(false);
    const [active, setActive] = useState(false);
    const [howItWorks, setHowItWorks] = useState(false);
    const [menu, setMenu] = useState(false);

    useEffect(() => {
        function handleScroll() {
            if (window.scrollY >= 5) setActive(true);
            else setActive(false);
        }
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (howItWorks) document.body.classList.add('overflow-hidden');
        else document.body.classList.remove('overflow-hidden');
    }, [howItWorks]);

    useEffect(() => {
        if (menu) document.body.classList.add('overflow-hidden');
        else document.body.classList.remove('overflow-hidden');
    }, [menu]);

    function MyLink({href,children,className}:{href:string, children:any, className:string} ) {
        const isActive = window.location.pathname === href;
        return (
            <a href={href} className={`${className} ${isActive && 'pr-4 border-r-4 border-[var(--primary)]'}`}>{children}</a>
        )
    }

    void setIsAuth;

    return (
        <div className={`fixed w-full px-[8vw] h-[5rem] rounded-b-[15px] seis:rounded-0 flex justify-between items-center bg-white z-[10] transition-all duration-300 ${active && 'shadow-bottom'}`}>
            <div className='flex gap-2 z-[99]'>
                <a href={'/'}><img src={Logo} alt="icon" className='w-[12rem]' /></a>
            </div>
            <div className='flex justify-end items-center gap-10'>
                <button onClick={() => setHowItWorks(true)} className='hidden oito:flex'>Como funciona</button>
                <a href={'/add-property'} className='font-garetBold hidden miledois:flex'>Adicionar propriedade</a>
                <a href={'/support'} className='hidden miledois:flex'>Contactar</a>
                <div className='oito:relative flex gap-2 items-center group'>
                    <div className='gap-2 items-center hidden oito:flex'>
                        <p>More</p>
                        <img src={ArrowDown} alt="icon" className='w-6' />
                    </div>
                    <div id='navbar-menu' className={`absolute bottom-[-19rem] oito:bottom-[-18rem] miledois:bottom-[-11.7rem] left-[-4rem] w-[17rem] quatro:w-[20rem] oito:w-[12rem] bg-[var(--white)] rounded-[25px] border-2 border-black p-6 hidden flex-col gap-1 group-hover:flex hover:flex ${menu && 'active'}`}>
                        <div className='flex justify-center w-full mb-8 oito:hidden'>
                            <a href={isAuth ? '/profile' : '/login'} className='py-2 px-6 bg-[var(--primary)] rounded-[10px] text-white oito:hidden flex'>{isAuth ? 'username' : 'Iniciar Sessão'}</a>
                        </div>
                        <MyLink href={'/'} className='flex oito:hidden'>Home</MyLink>
                        <button onClick={() => setHowItWorks(true)} className='flex oito:hidden'>Como funciona</button>
                        <MyLink href={'/add-property'} className='font-garetBold flex miledois:hidden'>Adicionar propriedade</MyLink>
                        <MyLink href={'/support'} className='flex miledois:hidden'>Contactar</MyLink>
                        <MyLink href={'/construction'} className='hover:underline'>Rent</MyLink>
                        <MyLink href={'/construction'} className='hover:underline'>Driver</MyLink>
                        <MyLink href={'/parceiros'} className='font-garetBold hover:underline'>Parceiros</MyLink>
                        <MyLink href={'/blog'} className='hover:underline'>Blog</MyLink>
                        <MyLink href={'/support'} className='hover:underline'>Ajuda</MyLink>
                    </div>
                </div>
                <a href={isAuth ? '/profile' : '/login'} className='py-2 px-6 bg-[var(--primary)] rounded-[10px] text-white oito:flex hidden'>{isAuth ? 'username' : 'Iniciar Sessão'}</a>
                <div onClick={() => setMenu(!menu)} className='oito:hidden flex flex-col gap-[4px] cursor-pointer'>
                    <div className={`w-6 h-[3px] ${menu ? 'rotate bg-[var(--primary)] rotate-45 translate-y-[.35rem]' : 'bg-black'} transition-all duration-300`}></div>
                    <div className={`w-6 h-[3px] bg-black ${menu && 'hidden'}`}></div>
                    <div className={`w-6 h-[3px] ${menu ? 'rotate bg-[var(--primary)] -rotate-45 translate-y-[-.10rem]' : 'bg-black'} transition-all duration-300`}></div>
                </div>
            </div>
            {howItWorks && <HowItWorks setPopup={setHowItWorks} />}
        </div>
    );
}

