import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

import HeaderBg from '../../content/images/home/header.jpg';

import Header from './components/header';
import About from './components/about';
import Selection from './components/selection';
import Services from './components/services';
import List from './components/listnow';
import Rent from './components/rentnow';
import Loading from '../../components/loading';

export default function Home() {
    const [texts, setTexts] = useState<any>(null);

    useEffect(() => {
        async function fetchTexts() {
            try {
                let docRef = doc(db, 'website', 'home');
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setTexts(data);
                }
            } catch (error) {
                console.error("Error getting document: ", error);
            }
        }
        fetchTexts();
    }, []);

    if (!texts) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full pt-[5rem] px-[8vw]'>
            <div className='oito:hidden flex absolute top-0 left-0 w-full h-[30rem]'>
                <img src={HeaderBg} alt="bg" className='absolute top-0 left-0 w-full h-full object-cover object-[100%_100%] z-[1]' />
            </div>
            <Header texts={texts?.header} />
            <About texts={texts?.about} />
            <Selection />
            <Services texts={texts?.services} />
            <List texts={texts?.list} />
            <Rent texts={texts?.rent} />
        </div>
    );
}

