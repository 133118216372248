import { useNavigate, useParams } from 'react-router-dom';
import { FaRegCheckCircle } from "react-icons/fa";
import { useEffect } from 'react';
import axios from 'axios';
import { useGarages } from '../../contexts/GaragesContext';
import { useVehicleContext } from '../../contexts/VehicleContext';
import { useDateTimeContext } from '../../contexts/DateTimeContext';

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const { garageName } = useParams<{ garageName: string }>();
    const garages = useGarages().garages;
    const { vehicleType } = useVehicleContext();
    const { startDate, endDate, startTime, endTime} = useDateTimeContext();
    
    useEffect(() => {
        const paymentStatus = sessionStorage.getItem(`payment${garageName}`);
        if (!paymentStatus) {
            navigate('/map');
        }
    }, [garageName, navigate]);

    const handleRedirect = () => {
        createBooking();
        sessionStorage.removeItem(`payment${garageName}`);
        navigate(`/preview/${garageName}`);
    }

    const dateConverter = (date: Date) => {
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    const createBooking = () => {
        let currentDate = new Date();
        const date = dateConverter(currentDate);
        const time = `${currentDate.getHours()}:${currentDate.getMinutes() < 10 ? '0' : ''}${currentDate.getMinutes()}`;
        const bookingData = sessionStorage.getItem(`booking${garageName}`);

        let booking = null;
        if (bookingData) {
            booking = JSON.parse(bookingData);
        }
        
        const garage = garages.find((garage: { name: string | undefined; }) => garage.name === garageName);
        
        let bookingPrice = 0;
        if (garage?.price) {
            const price = garage?.price.find((price: { type: string; value: Number }) => price.type === vehicleType);
            bookingPrice = price.value;
        }

        const newBooking = {
            date: date,
            time: time,
            client: {
                name: booking.name,
                phone: booking.phone,
                email: booking.email,
            },
            park: garageName,
            row: "N/A",
            spot: "N/A",
            extraServices: garage.services ? garage.services : [],
            bookingPrice: Number(bookingPrice),
            checkIn: dateConverter(startDate),
            checkInTime: startTime,
            checkOut: dateConverter(endDate),
            checkOutTime: endTime,
            garageId: garage.id,
            userId: garage.userId,
        }

        try {
            axios.post(`${process.env.REACT_APP_API_URL}/api/v1/booking/create`, newBooking,

            ).then((response) => {
                console.log('Booking created successfully:', response);

            }).catch((error) => {
                console.error('Error creating booking:', error);
            });

        }
        catch (error) {
            console.error('Error creating booking:', error);
            return;
        }

    }

    return (
        <div className="min-h-screen bg-green-50 flex flex-col justify-center items-center">
            <div className="bg-white p-10 rounded-lg shadow-lg max-w-md text-center">
                <div className='w-full flex justify-center pb-4'>
                    <FaRegCheckCircle size={50} color='rgb(22 163 74)' />
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-2">Pagamento bem-sucedido!</h2>
                <p className="text-gray-600 mb-6">Obrigado pela sua reserva. O seu pagamento foi processado com sucesso.</p>
                <button onClick={handleRedirect} className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75">
                    Voltar para visualização da garagem
                </button>
            </div>
        </div>
    );
};

export default PaymentSuccess;
