import React from 'react';

interface CheckboxWithLabelProps {
  label: string;
  checked: boolean;
  onChange: () => void;
  error?: string;
}

const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({ label, checked, onChange, error }) => {
  return (
    <div className='flex items-center gap-2'>
      <div onClick={onChange} className={`w-5 h-5 shrink-0 border-gray-300 border-1 rounded-[4px] cursor-pointer ${checked ? 'bg-[var(--primary)]' : ' bg-gray-200'}`}></div>
      <p className='font-garet p-4 text-[0.9rem]'>{label}</p>
      {error && <p className='text-red-500'>{error}</p>}
    </div>
  );
};

export default CheckboxWithLabel;
