import React, { createContext, useState, useContext, useEffect } from 'react';

const VehicleTypeContext = createContext<{
  vehicleType: string;
  setVehicleType: React.Dispatch<React.SetStateAction<string>>;
}>({
  vehicleType: '',
  setVehicleType: () => {},
});

export const VehicleTypeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [vehicleType, setVehicleType] = useState(() => {
        // Get the initial value from sessionStorage
        const savedVehicleType = sessionStorage.getItem('vehicleType');
        return savedVehicleType ? savedVehicleType : 'carro';
    });

    // Use useEffect to update the sessionStorage whenever vehicleType changes
    useEffect(() => {
        sessionStorage.setItem('vehicleType', vehicleType);
    }, [vehicleType]);

    return (
        <VehicleTypeContext.Provider value={{ vehicleType, setVehicleType }}>
            {children}
        </VehicleTypeContext.Provider>
    );
};

export function useVehicleContext() {
    const context = useContext(VehicleTypeContext);
    if (!context) {
      throw new Error('VehicleTypeContext is null, component must be used inside a VehicleTypeProvider');
    }
    return context;
}