import React, { useState } from 'react';

import Driver from '../../../content/images/icons/pilot.png';
import Service from '../../../content/images/icons/service-1.png';
import Service2 from '../../../content/images/icons/service-4.png';
import Service3 from '../../../content/images/icons/service-5.png';
import Service4 from '../../../content/images/icons/service-6.png';
import Clock from '../../../content/images/icons/service-2.png';
import Light from '../../../content/images/icons/service-3.png';
import Route from '../../../content/images/icons/route.png';

export default function Services({texts}:any) {

    const [section, setSection] = useState(1);

    function SectionSelected({ ...props }) {
        return (
            <div className='services-section w-full flex flex-col gap-8' style={{ display: section === props.index ? 'flex' : 'none' }}>
                <p className='text-[2rem] seis:text-[2.5rem] font-garetBold text-balance'>{props.title}</p>
                <p className='w-full mil:w-[70%] text-[.9rem] text-balance'>{props.text}</p>
                <div className='flex seis:flex-row flex-col gap-[6vw]'>
                    <div className='flex gap-4 items-center'>
                        <img src={props.image1} alt="icon" className='w-[5rem]' />
                        <div className='flex flex-col'>
                            <p className='font-garetBold text-[1.5rem]'>{props.data1}</p>
                            <p>{props.info1}</p>
                        </div>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <img src={props.image2} alt="icon" className='w-[5rem]' />
                        <div className='flex flex-col'>
                            <p className='font-garetBold text-[1.5rem]'>{props.data2}</p>
                            <p>{props.info2}</p>
                        </div>
                    </div>
                </div>
                <button className='w-[10rem] seis:w-[15rem] text-white rounded-full px-6 py-2 seis:py-4 bg-[var(--primary)] text-center'>Book now</button>
                {/* <img src="" alt="icon" className='' /> */}
            </div>
        )
    }

    return (
        <div className='w-full h-full flex flex-col gap-16 py-[5rem]'>
            <h3 className='font-garetBold text-[1.7rem] quatro:text-[2rem] seis:text-[2.5rem]'>Serviços</h3>
            <div className='w-full flex mil:flex-row flex-col justify-between gap-[4rem] mil:gap-[12vw]'>
                <div className='w-full mil:w-[12rem] grid grid-cols-2 seis:flex flex-row mil:flex-col mil:gap-8 seis:gap-2 gap-4 uppercase'>
                    <button onClick={() => setSection(1)} className={`w-full h-[7rem] px-2 rounded-[20px] text-[.7rem] quatro:text-[.8rem] mil:text-[1rem] font-garetBold uppercase ${section === 1 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}>Drivers - Valet Parking</button>
                    <button onClick={() => setSection(2)} className={`w-full h-[7rem] px-2 rounded-[20px] text-[.7rem] quatro:text-[.8rem] mil:text-[1rem] font-garetBold uppercase ${section === 2 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}>Estacionamento</button>
                    <button onClick={() => setSection(3)} className={`w-full h-[7rem] px-2 rounded-[20px] text-[.7rem] quatro:text-[.8rem] mil:text-[1rem] font-garetBold uppercase ${section === 3 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}>Elétricos</button>
                    <button onClick={() => setSection(4)} className={`w-full h-[7rem] px-2 rounded-[20px] text-[.7rem] quatro:text-[.8rem] mil:text-[1rem] font-garetBold uppercase ${section === 4 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}>Serviços Extra</button>
                </div>
                <SectionSelected
                    title={texts ? texts['service1'].title : ''}
                    text={texts ? texts['service1'].text : ''}
                    image1={Driver}
                    data1='150+'
                    info1='Professional Drivers'
                    image2={Service}
                    data2='3300+'
                    info2='Parking Lots'
                    index={1}
                />
                <SectionSelected
                    title={texts ? texts['service2'].title : ''}
                    text={texts ? texts['service2'].text : ''}
                    image1={Service}
                    data1='3300+'
                    info1=' Parking Lots'
                    image2={Clock}
                    data2='24H'
                    info2='Open parks'
                    index={2}
                />
                <SectionSelected
                    title={texts ? texts['service3'].title : ''}
                    text={texts ? texts['service3'].text : ''}
                    image1={Light}
                    data1='300+'
                    info1='Eletric Stations'
                    image2={Route}
                    data2='24H'
                    info2='Pick-up and delivery'
                    index={3}
                />
                <div className={`services-section ${section !== 4 && 'hidden'} w-full flex flex-col gap-[4rem]`}>
                    <p className='text-[2rem] seis:text-[2.5rem] font-garetBold text-balance'>{texts ? texts['service4'].title : ''}</p>
                    <div className='flex flex-col gap-16 seis:gap-8'>
                        <div className='flex seis:flex-row flex-col items-center gap-6'>
                            <div className='bg-[var(--primary)] rounded-[25px] seis:rounded-[1.5vw] flex justify-center items-center w-[6rem] seis:w-[8rem] aspect-square'><img src={Service2} alt="icon" className='w-[4.5rem] invert' /></div>
                            <div className='flex flex-col w-full'>
                                <p className='font-garetBold'>CarHealth - Analise o carro</p>
                                <p className=''>{texts ? texts['service4'].text1 : ''}</p>
                            </div>
                            <button className='w-[15rem] text-white rounded-full px-6 py-2 bg-[var(--primary)] text-center'>Ver mais</button>
                        </div>
                        <div className='flex seis:flex-row flex-col items-center gap-6'>
                            <div className='bg-[var(--primary)] rounded-[25px] seis:rounded-[1.5vw] flex justify-center items-center w-[6rem] seis:w-[8rem] aspect-square'><img src={Service3} alt="icon" className='w-[4.5rem] invert' /></div>
                            <div className='flex flex-col w-full'>
                                <p className='font-garetBold'>Lavagem</p>
                                <p className=''>{texts ?  texts['service4'].text2 : ''}</p>
                            </div>
                            <button className='w-[15rem] text-white rounded-full px-6 py-2 bg-[var(--primary)] text-center'>Ver mais</button>
                        </div>
                        <div className='flex seis:flex-row flex-col items-center gap-6'>
                            <div className='bg-[var(--primary)] rounded-[25px] seis:rounded-[1.5vw] flex justify-center items-center w-[6rem] seis:w-[8rem] aspect-square'><img src={Service4} alt="icon" className='w-[4.5rem] invert' /></div>
                            <div className='flex flex-col w-full'>
                                <p className='font-garetBold'>IPO</p>
                                <p className=''>{texts ?  texts['service4'].text3 : ''}</p>
                            </div>
                            <button className='w-[15rem] text-white rounded-full px-6 py-2 bg-[var(--primary)] text-center'>Ver mais</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

