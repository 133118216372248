import React, { useState } from 'react';

import Selection1 from '../../../content/images/home/selection.jpg';
import Selection3 from '../../../content/images/home/selection-3.jpg';
import Selection4 from '../../../content/images/home/selection-4.jpg';
import PlusIcon from '../../../content/images/icons/plus.png';
import { useNavigate } from 'react-router-dom';

export default function Selection() {

    const navigate = useNavigate();
    const [section, setSection] = useState(1);

    function Card({ ...props }) {
        return (
            <div className='relative w-full max-w-[17rem] h-[20rem] flex items-end justify-between rounded-[15px] overflow-hidden cursor-pointer p-4 text-white text-[1.2rem] group place-self-center' >
                <img src={props.image} alt="card" className='absolute w-full h-full inset-0 object-cover transition-all duration-300 group-hover:blur-[4px] z-[1]' />
                <div className='z-[2]'>
                    <p className='uppercase font-garetBold'>{props.title}</p>
                    <div className='flex gap-2 items-center'>
                        <p className='font-garetBold'>{props.price}€</p>
                        <p className='text-[.8rem]'>Round trip</p>
                    </div>
                </div>
                <img src={PlusIcon} alt="plus" className='w-8 z-[2] invert' />
            </div>
        )
    }

    return (
        <div className='w-full h-full flex flex-col gap-16'>
            <div className='w-full grid grid-cols-2 seis:grid-cols-3 mil:flex justify-center gap-4 mil:gap-8 uppercase mil:px-8'>
                <button onClick={() => setSection(1)} className={`w-full h-[7rem] rounded-[20px] px-2 font-garetBold text-[.8rem] quatro:text-[1rem] uppercase ${section === 1 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}>Aeroporto</button>
                <button onClick={() => setSection(2)} className={`w-full h-[7rem] rounded-[20px] px-2 font-garetBold text-[.8rem] quatro:text-[1rem] uppercase ${section === 2 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}>Cidade</button>
                <button onClick={() => setSection(3)} className={`w-full h-[7rem] rounded-[20px] px-2 font-garetBold text-[.8rem] quatro:text-[1rem] uppercase ${section === 3 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}>Eventos</button>
                <button onClick={() => setSection(4)} className={`w-full h-[7rem] rounded-[20px] px-2 font-garetBold text-[.8rem] quatro:text-[1rem] uppercase ${section === 4 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}>Parquimetros</button>
                <button onClick={() => setSection(5)} className={`w-full h-[7rem] rounded-[20px] px-2 font-garetBold text-[.8rem] quatro:text-[1rem] uppercase ${section === 5 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}>Centros</button>
            </div>
            <h3 className='uppercase font-garetBold text-[1.5rem]'>Em destaque</h3>
            <div className='relative w-full grid grid-cols-1 seis:grid-cols-2 mil:flex justify-between gap-[1rem]'>
                <Card title="Redpark" price="40" type="aeroporto" image={Selection1} />
                <Card title="Redpark" price="40" type="aeroporto" image={Selection4} />
                <Card title="Redpark" price="40" type="aeroporto" image={Selection1} />
                <div onClick={() => navigate('/map')} className='relative w-full max-w-[17rem] h-[20rem] flex items-end justify-between rounded-[15px] overflow-hidden cursor-pointer p-4 text-white text-[1.2rem] group place-self-center' >
                    <img src={Selection3} alt="card" className='absolute w-full h-full inset-0 object-cover transition-all duration-300 group-hover:blur-[4px] z-[1]' />
                    <div className='uppercase text-[1.2rem] z-[2] w-full flex justify-center pb-2'>
                        <p className='font-garetBold'>Ver mais</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

