import React, { useState } from 'react';
import Input from '../components/input';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const login = async () => {
        if (email === '' || password === '') {
            alert('Preencha todos os campos.')
            return ;
        }
        await signInWithEmailAndPassword(auth, email, password)
        .then(() => {
            localStorage.setItem('email', email);
            window.location.href = '/';
        })
        .catch(() => {
            alert('Email ou password incorretos.')
            return ;
        });
    }


    return (
        <div className='pt-[10rem] flex flex-col gap-[3rem] pb-[10rem]'>
            <h1 className='font-garetBold text-[1.2rem] quatro:text-[2rem] text-center'>Iniciar sessão</h1>
            <div className='flex justify-center w-full'>
                <div className='w-full seis:w-[25rem] flex flex-col items-center gap-8'>
                    <Input label='Email' value={email} setValue={setEmail} type="email" />
                    <Input label='Password' value={password} setValue={setPassword} type="password" />
                    <button onClick={login} className='w-full rounded-full bg-[var(--primary)] px-4 py-2 text-white'>Entrar</button>
                    <p className='text-center text-[.8rem] text-balance'>Novo na <span className='uppercase font-garetBold'>Multipark</span>? Faça o seu registo <a href="/signup" className='underline'>aqui</a></p>
                </div>
            </div>
        </div>
    );
}

