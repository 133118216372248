import React from 'react';

export default function Partners() {
    return (
        <div className='w-full h-[10rem] flex gap-[5rem] items-center bg-[var(--primary)] text-white overflow-x-hidden px-[8vw]'>
            <p className='w-[20rem]'>Parceiro 1</p>
            <p className='w-[20rem]'>Parceiro 2</p>
            <p className='w-[20rem]'>Parceiro 3</p>
            <p className='w-[20rem]'>Parceiro 4</p>
            <p className='w-[20rem]'>Parceiro 5</p>
            <p className='w-[20rem]'>Parceiro 6</p>
            <p className='w-[20rem]'>Parceiro 7</p>
            <p className='w-[20rem]'>Parceiro 8</p>
        </div>
    );
}

