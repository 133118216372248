import React, { createContext, useState, useContext } from 'react';

// Definir a interface para o contexto do usuário
interface UserContextType {
  user: {
    name: string;
    email: string;
    token: string;
  } | null;
  setUser: React.Dispatch<React.SetStateAction<{
    name: string;
    email: string;
    token: string;
  } | null>>;
}

// Criar o contexto
const UserContext = createContext<UserContextType | null>(null);

// Criar o componente provedor
export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<{
    name: string;
    email: string;
    token: string;
  } | null>(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Criar um hook para utilizar o contexto do usuário
export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContext is null, component must be used inside a UserProvider');
  }
  return context;
}
