
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { IoArrowBack } from "react-icons/io5";

import CheckOutCard from "./CheckOutCard";
import toast, { Toaster } from "react-hot-toast";
import { TbInfoCircleFilled } from "react-icons/tb";
import { useGarages } from "../../contexts/GaragesContext";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { PhoneNumberUtil } from 'google-libphonenumber';


export default function CheckOut() {
    const navigate = useNavigate();
    const { garageName } = useParams<{ garageName: string }>();
    const phoneUtil = PhoneNumberUtil.getInstance();
    const [name, setName] = useState<string>('');
    const [surname, setSurname] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [terms, setTerms] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>('');

    const [garage, setGarage] = useState<any>(null);

    const garages = useGarages().garages;

    useEffect(() => {
        // Get values from context when component mounts

        // Find the garage with the name that matches `garageName`
        const garage = garages.find((garage: { name: string | undefined; }) => garage.name === garageName);

        if (garage) {
            setGarage(garage);
        }

        const bookingData = sessionStorage.getItem(`booking${garageName}`);

        if (bookingData) {
            const data = JSON.parse(bookingData);
            setName(data.name);
            setSurname(data.surname);
            setEmail(data.email);
            setPhone(data.phone);
        }

    }, [garageName, garages]);



    const handlePayment = () => {
        if (!name || !surname || !email) {
            toast.error('Por favor preencha todos os campos');
            return;
        }

        if (!validateEmail(email)) {
            toast.error('Por favor insira um email válido');
            return;
        }

        if (!isPhoneValid(phone)) {
            toast.error('Por favor insira um número de telefone válido');
            return;
        }

        if (!terms) {
            toast.error('Por favor aceite os termos e condições');
            return;
        }

        

        const bookingData = {
            name,
            surname,
            email,
            phone,
        };
        // Store booking data in session storage, so it can be accessed in the payment page
        sessionStorage.setItem(`booking${garageName}`, JSON.stringify(bookingData));
        navigate(`/checkout/payment/${garage?.name}`);
    }

    const validateEmail = (email: any) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const isPhoneValid = (phone: string) => {
        try {
          return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
        } catch (error) {
          return false;
        }
      };

    return (
        <div className='relative pt-[8rem] gap-12 seis:px-[4vw] px-[6vw]'>
            <Toaster />
            <div className="py-10">
                <Link to={`/preview/${garageName}`}>
                    <div className="flex text-lg font-extrabold w-fit cursor-pointer "><IoArrowBack title="Voltar atrás" size={28} /><p className="flex items-center">Voltar</p></div>
                </Link>
            </div>

            <div className="py-12 miledois:flex gap-4 min-h-full">
                <div className="w-full miledois:w-[60%]">
                    <div className="p-4">
                        <h2 className="text-2xl font-semibold pb-8">Os meus dados</h2>
                        <div className="grid grid-cols-1 mil:grid-cols-2 gap-8">
                            <div>
                                <input
                                    type="text"
                                    value={name}
                                    placeholder="Nome"
                                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div>
                                <input
                                    value={surname}
                                    type="email"
                                    placeholder="Apelido"
                                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    onChange={(e) => setSurname(e.target.value)}
                                />
                            </div>
                            <div className="mil:col-span-2">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <p className="text-xs text-zinc-500 mt-1">
                                    A confirmação da reserva será enviada para este endereço de correio eletrónico
                                </p>
                            </div>
                            <div className="mil:col-span-2">
                                <PhoneInput
                                
                                    defaultCountry="pt"
                                    value={phone}
                                    onChange={(phone) => setPhone(phone)}
                                />
                            </div>
                            <div>
                                <div className="flex items-center space-x-2">
                                    <input type="checkbox" id="terms" checked={terms} className="form-checkbox h-4 w-4 text-zinc-600"
                                        onChange={(e) => setTerms(e.target.checked)} />
                                    <label htmlFor="terms" className="text-zinc-800">Aceito os <Link to={"/terms"} className="text-blue-600">Termos e condições</Link></label>
                                </div>
                            </div>
                        </div>
                        <p className="text-sm text-zinc-500 py-4">*Todos os campos são de preenchimento obrigatório</p>

                    </div>

                </div>
                <div className="w-full pt-10 miledois:pt-0 miledois:w-[40%]">
                    <CheckOutCard garage={garage} />
                </div>
            </div>
            <div className="pb-10 flex flex-col items-center justify-center gap-2">
                <div className="flex text-[var(--primary)]">
                    <TbInfoCircleFilled title="Info" size={20} />
                    <p className="pl-2 text-sm">Cancelamento gratuito</p>
                </div>
                <button onClick={handlePayment} className="w-72 bg-red-500 text-white py-3 rounded-lg">Proceder para pagamento</button>
            </div>
        </div>
    );

}

