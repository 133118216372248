import Logo from '../content/images/logos/logo.png';

export default function Loading() {
    return (
        <div className='absolute top-0 left-0 w-full h-screen bg-[var(--primary)] flex flex-col justify-center items-center z-[99] text-white gap-6'>
            <img src={Logo} alt="logo" className='w-[20rem] invert' />
            <p>Loading...</p>
        </div>
    );
}

