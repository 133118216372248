
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown';

export default function PostPage() {

    const { titulo = "" } = useParams();
    let pt = localStorage.getItem('lang') !== null && localStorage.getItem('lang') === "pt" ? true : false;

    const [post, setPost] = useState<any>(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/posts/${process.env.REACT_APP_BLOG_USER}/${titulo}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                const post = await response.json();
                setPost(post);
            } catch (error) {
                console.error('Fetching user posts failed:', error);
                return null;
            }
        }
        if (titulo !== '')
            fetchData();
    }, [titulo]);

    if (!post) {
        return (
            <div className='relative w-full min-h-screen flex justify-center items-center'>
                <CircularProgress color="inherit" />
            </div>
        )
    }

    return (
        <div className='w-full py-[8rem] bg-[var(--darkBlue)] min-h-screen flex flex-col items-center'>
            <div className='relative text-left flex flex-col gap-6 w-full items-center'>
                <div className='w-[90%] cinco:w-[70%] flex flex-col gap-6 text-left'>
                    <h1 className='text-[2rem] font-garetBold'>{post?.titulo}</h1>
                    <h2 className='text-[1.5rem] font-bold text-[var(--primary)]'>{post?.descricao}</h2>
                    <div>
                        <p>{pt ? 'De:' : 'By:'} <b>{post?.autor}</b></p>
                        <p>{pt ? 'Último update:' : 'Last update:'} &ensp; <b>{post?.dataEdicao}</b></p>
                    </div>
                    <img src={post?.imagem} alt="postImage" className='h-[20rem] object-cover' />
                </div>
                <div
                    className='relative py-[2rem] w-[90%] cinco:w-[70%] border-y-[1px] border-[grey] flex flex-col items-center min-h-[25rem] gap-6 text-left'
                    style={{ whiteSpace: 'pre-wrap' }}
                >
                    <section className="markdown">
                        <Markdown className="w-full">{post?.article}</Markdown>
                    </section>
                </div>
            </div>
        </div>
    );
}

