import React from 'react';
import AboutImage from '../../../content/images/home/about.jpg';

export default function About({texts}:any) {

    return (
        <div className='w-full flex mil:flex-row flex-col justify-between items-center mil:gap-4 gap-16 py-[5rem]'>
            <div className='w-full mil:w-[60%] flex flex-col gap-10'>
                <h2 className='font-garetBold text-[1.4rem] quatro:text-[1.7rem] seis:text-[2.2rem] text-balance'>{texts?.title || 'Loading...'}</h2>
                <p className='w-full oito:w-[85%]'>{texts?.text || 'Loading...'}</p>
            </div>
            <div className='w-full seis:w-[27rem] mil:w-[40%] flex justify-center items-center'>
                <img src={AboutImage} alt="about" className='object-cover w-full' />
            </div>
        </div>
    );
}

