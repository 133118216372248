import React from 'react';
import Service from '../../../content/images/icons/park.png';
import Service2 from '../../../content/images/icons/price.png';
import Service3 from '../../../content/images/icons/agreement.png';
import Service4 from '../../../content/images/icons/service-7.png';

export default function Rent({texts}:any) {
    return (
        <div className='w-full flex flex-col py-[10rem] gap-[4rem]'>
            <div className='w-full flex justify-center'>
                <h3 className='font-garetBold text-[1.7rem] quatro:text-[2rem] seis:text-[2.5rem] text-balance mil:w-[40rem] text-center'>{texts?.title}</h3>
            </div>
            <div className='w-full grid grid-cols-2 seis:flex seis:justify-around gap-4'>
                <div className='flex flex-col gap-4 items-center w-full text-center text-balance'>
                    <img src={Service} alt="icon" className='w-[6rem] quatro:w-[8rem]' />
                    <p className='uppercase font-garetBold w-full text-[.8rem] mil:text-[1rem] mil:w-[60%]'>Place your car</p>
                </div>
                <div className='flex flex-col gap-4 items-center w-full text-center text-balance'>
                    <img src={Service2} alt="icon" className='w-[6rem] quatro:w-[8rem]' />
                    <p className='uppercase font-garetBold w-full text-[.8rem] mil:text-[1rem] mil:w-[60%]'>SET THE PRICE /H</p>
                </div>
                <div className='flex flex-col gap-4 items-center w-full text-center text-balance'>
                    <img src={Service3} alt="icon" className='w-[6rem] quatro:w-[8rem]' />
                    <p className='uppercase font-garetBold w-full text-[.8rem] mil:text-[1rem] mil:w-[60%]'>NEGOTIATE AND TALK WITH THE RENTER</p>
                </div>
                <div className='flex flex-col gap-4 items-center w-full text-center text-balance'>
                    <img src={Service4} alt="icon" className='w-[7rem] quatro:w-[8rem]' />
                    <p className='uppercase font-garetBold w-full text-[.8rem] mil:text-[1rem] mil:w-[60%]'>SAFETY DRIVE AND SECURE RETURN</p>
                </div>
            </div>
            <div className='w-full flex justify-center'>
                <button onClick={() => window.location.href = "/construction"} className='w-[12rem] font-garetBold text-white rounded-full px-6 py-3 bg-[var(--primary)] text-center'>Rent now</button>
            </div>
            <div className='w-full flex justify-center gap-[2rem] mil:gap-[5rem]'>
                <div>
                    <p className='font-garetBold text-[1.5rem] text-center seis:text-left'>120+</p>
                    <p className='text-[1.2rem] text-center seis:text-left'>Renting people</p>
                </div>
                <div>
                    <p className='font-garetBold text-[1.5rem] text-center seis:text-left'>260+</p>
                    <p className='text-[1.2rem] text-center seis:text-left'>Renting cars</p>
                </div>
                <div>
                    <p className='font-garetBold text-[1.5rem] text-center seis:text-left'>120+</p>
                    <p className='text-[1.2rem] text-center seis:text-left'>Renting people</p>
                </div>
            </div>
            <div className='flex justify-center'>
                <p className='w-full oito:w-[80%] mil:w-[40%] text-center'>{texts?.text}</p>
            </div>
        </div>
    );
}

