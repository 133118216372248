import React from 'react';
import Service1 from '../content/images/icons/parkLight.png';
import Price from '../content/images/icons/price.png';
import Charger from '../content/images/icons/charger.png';
import Service2 from '../content/images/icons/cash-1.png';

export default function AddCharger({ setPopup, type, setType }: { setPopup: any, type: number, setType: any }) {
    return (
        <div className='fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-[rgba(.5,.5,.5,.3)] z-[99]'>
            <div className='w-full h-full seis:h-auto  seis:w-[35rem] bg-[var(--white)] p-8 border-2 border-black seis:rounded-[25px] flex flex-col uppercase gap-8'>
                <div className='flex justify-between'>
                    <p className='font-garetBold seis:text-[1.2rem] text-[1rem] uppercase'>ADD CHARGER</p>
                    <p onClick={() => setPopup(false)} className='font-garetBold text-[1.5rem] seis:text-[2rem] cursor-pointer'>X</p>
                </div>
                <div className='w-full h-full flex flex-col gap-5'>
                    <p className='normal-case'>Choose one type:</p>
                    <div className='w-full flex seis:flex-row flex-col gap-4 seis:gap-[3rem]'>
                        <button onClick={() => setType(1)} className={`w-full h-[7rem] rounded-[20px] flex flex-col gap-4 justify-center items-center ${type === 1 ? 'bg-[var(--primary)] text-white' : 'bg-white'}`}>
                            <p className='font-garetBold'>30 KWa/H</p>
                            <p className='font-garetBold'>Até 1 carro</p>
                        </button>
                        <button onClick={() => setType(2)} className={`w-full h-[7rem] rounded-[20px] flex flex-col gap-4 justify-center items-center ${type === 2 ? 'bg-[var(--primary)] text-white' : 'bg-white'}`}>
                            <p className='font-garetBold'>50 KWa/H</p>
                            <p className='font-garetBold'>Até 2 carros</p>
                        </button>
                        <button onClick={() => setType(3)} className={`w-full h-[7rem] rounded-[20px] flex flex-col gap-4 justify-center items-center ${type === 3 ? 'bg-[var(--primary)] text-white' : 'bg-white'}`}>
                            <p className='font-garetBold'>150 KWa/H</p>
                            <p className='font-garetBold'>Até 4 carros</p>
                        </button>
                    </div>
                </div>
                <div className='flex flex-col gap-[2rem]'>
                    <div className='w-full flex justify-center'>
                        <p className='uppercase font-garetBold text-center seis:w-1/2 text-balance'>Get money with a <span className='text-[var(--primary)] uppercase font-garetBold'>supercharger</span> at home</p>
                    </div>
                    <div className='seis:flex gap-10 hidden'>
                        <div className="flex flex-col gap-4 items-center justify-end">
                            <img src={Service1} alt="icon" className='w-16' />
                            <p className='uppercase font-garetBold text-[.7rem] text-center'>Place your charger</p>
                        </div>
                        <div className="flex flex-col gap-4 items-center justify-end">
                            <img src={Price} alt="icon" className='w-16' />
                            <p className='uppercase font-garetBold text-[.7rem] text-center'>Book garage w/ eletric charge</p>
                        </div>
                        <div className="flex flex-col gap-4 items-center justify-end">
                            <img src={Charger} alt="icon" className='w-16' />
                            <p className='uppercase font-garetBold text-[.7rem] text-center'>Be a charging station</p>
                        </div>
                        <div className="flex flex-col gap-4 items-center justify-end">
                            <img src={Service2} alt="icon" className='w-20' />
                            <p className='uppercase font-garetBold text-[.7rem] text-center'>Get reward</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

