import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
    const navigate = useNavigate();
    return (
        <div className='h-[100dvh] h-screen flex flex-col justify-center items-center gap-3'>
            <p className='text-[2rem] text-[var(--primary)] font-garetBold'>404 Error</p>
            <p>This page does not exist</p>
            <button onClick={() => navigate(-1)} className='w-[10rem] bg-[var(--primary)] py-2 text-white rounded-full'>← Go Back</button>
        </div>
    );
}

