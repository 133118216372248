import  { useState } from 'react';
import HeaderBg from '../../../content/images/home/header.jpg';
import ClockIcon from '../../../content/images/icons/clock.png';
import PlaneIcon from '../../../content/images/icons/plane.png';
import HouseIcon from '../../../content/images/icons/house.png';
import SearchIcon from '../../../content/images/icons/search.png';
import LeftArrowIcon from '../../../content/images/icons/left-arrow.png';
import RightArrowIcon from '../../../content/images/icons/right-arrow.png';
import PlusIcon from '../../../content/images/icons/plus.png';
import Parks from './parks.json';
import { useNavigate } from 'react-router-dom';
import SelectDateTime from './DateTimePicker';
import { useDateTimeContext } from '../../../contexts/DateTimeContext';

export default function Header({ texts }: any) {
    const navigate = useNavigate();
    const [selected, setSelected] = useState(1);
    const [search, setSearch] = useState('');
    const [park, setPark] = useState(0);
    const [isOpenStart, setIsOpenStart] = useState(false);
    const [isOpenEnd, setIsOpenEnd] = useState(false);
    const today = new Date();
    const { startDate, setStartDate, endDate, setEndDate, startTime, setStartTime, endTime, setEndTime } = useDateTimeContext();

    const handleNext = () => {
        setPark((prev) => prev + 1 === Parks.length ? 0 : prev + 1);
    };

    const handlePrev = () => {
        setPark((prev) => prev - 1 < 0 ? Parks.length - 1 : prev - 1);
    };

    return (
        <header className='relative w-full flex flex-col items-center'>
            <div className='relative w-full pt-8 pb-16 oito:px-8 flex flex-col gap-[3rem] bg-cover bg-center z-[1]' >
                <div className='hidden oito:flex'>
                    <img src={HeaderBg} alt="bg" className='absolute top-0 left-0 w-full h-full rounded-[15px] object-cover object-[100%_100%] z-[1]' />
                    <div className='absolute bottom-[-2rem] left-[-2rem] bg-[var(--primary)] h-[20rem] w-[20rem] rounded-[35px] z-[0]'></div>
                </div>
                <div className='relative w-full flex justify-between gap-4 text-[white] z-[2]'>
                    <div className='w-full oito:w-[80%] milecem:w-[60%] h-full flex flex-col gap-4 oito:gap-[2rem]'>
                        <h1 className='text-[1.7rem] quatro:text-[2rem] oito:text-[3rem] milecem:text-[3.5rem] font-garetBold text-balance'>{texts?.title || 'Loading...'}</h1>
                        <h2 className='font-thin seis:text-[1rem] text-[.8rem]'>{texts?.text1 || '...'}</h2>
                        <p className='seis:text-[.9rem] miledois:text-[1rem] text-[.7rem] w-full px-4 flex justify-center items-center rounded-[10px] oito:rounded-full bg-[var(--primary)] py-2'>{texts?.text2 || '...'}</p>
                    </div>
                    <div className='w-[40%] h-[70%] milecem:h-full hidden mil:flex gap-2 justify-center items-center'>
                        <img onClick={handlePrev} src={LeftArrowIcon} alt="icon" className='w-8 invert cursor-pointer' />
                        {
                            Parks.length !== 0 ? Parks.map((ele: any, index: number) => {
                                return (
                                    <>
                                        {
                                            index === park &&
                                            <div id='header-slide1' className='relative w-[16rem] h-[18rem] rounded-[15px] overflow-hidden border-2 border-white flex justify-between items-end py-4 px-6'>
                                                <img src={ele?.image} alt="icon" className='absolute top-0 left-0 w-full h-full object-cover object-[70%] z-[1] brightness-75' />
                                                <div className='relative z-[2] leading-6'>
                                                    <p className='text-[.8rem]'>{ele?.location}</p>
                                                    <p className='text-[1rem] milecem:text-[1.2rem] uppercase font-garetBold'>{ele?.city}</p>
                                                    <p className='text-[1.5rem] font-garetBold'>{ele?.price}€</p>
                                                </div>
                                                <img onClick={() => navigate('/map')} src={PlusIcon} alt="icon" className='w-8 invert z-[2] cursor-pointer' />
                                            </div>
                                        }
                                        {
                                            index === park &&
                                            <div id='header-slide2' className='relative w-[6rem] h-[18rem] rounded-[15px] overflow-hidden border-2 border-white'>
                                                <img src={park + 1 === Parks.length ? Parks[0].image : Parks[park + 1].image} alt="icon" className='absolute top-0 left-0 w-full h-full object-cover object-[70%]' />
                                            </div>
                                        }
                                    </>
                                )
                            }) :
                                <p className='text-center w-full'>Nenhum resultado</p>
                        }
                        <img onClick={handleNext} src={RightArrowIcon} alt="icon" className='w-8 invert cursor-pointer' />
                    </div>
                </div>
                <div className='w-full overflow-x-auto'>
                    <div className='relative w-[55rem] oito:w-full flex oito:justify-center gap-6 z-[2] overflow-x-auto '>
                        <button onClick={() => setSelected(1)} className={`w-[18rem] seis:w-full px-4 mil:px-8 py-2 miledois:py-3 flex gap-4 uppercase rounded-[10px] oito:rounded-full font-garetBold justify-center items-center ${selected === 1 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}><img src={ClockIcon} alt="icon" className={`w-5 ${selected === 1 && 'invert'}`} />Cidades e parques</button>
                        <button onClick={() => setSelected(2)} className={`w-[18rem] seis:w-full px-4 mil:px-8 py-2 miledois:py-3 flex gap-4 uppercase rounded-[10px] oito:rounded-full font-garetBold justify-center items-center ${selected === 2 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}><img src={PlaneIcon} alt="icon" className={`w-6 ${selected === 2 && 'invert'}`} />Aeroportos - Valet</button>
                        <button onClick={() => setSelected(3)} className={`w-[18rem] seis:w-full px-4 mil:px-8 py-2 miledois:py-3 flex gap-4 uppercase rounded-[10px] oito:rounded-full font-garetBold justify-center items-center ${selected === 3 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}><img src={HouseIcon} alt="icon" className={`w-7 ${selected === 3 && 'invert'}`} />Assinaturas mensais</button>
                    </div>
                </div>
            </div>
            <div className='relative bottom-8 w-full seis:w-[90%] bg-[var(--white)] shadow-bottom px-4 miledois:px-10 py-2 mil:py-6 rounded-[10px] mil:rounded-[25px] flex justify-between items-center z-[2]'>
                <input
                    type="text"
                    placeholder='Estaciona em endereço, local...'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className='mil:w-1/3 w-full bg-[transparent] px-4 mr-8 outline-none mil:border-r-2 border-[grey]'
                />
                <div className='hidden mil:flex gap-2 miledois:gap-6'>
                    <SelectDateTime
                        isOpen={isOpenStart} setIsOpen={setIsOpenStart}
                        date={startDate} setDate={setStartDate}
                        time={startTime} setTime={setStartTime} today={today} start={true} />
                </div>
                <div className='hidden mil:flex gap-2 miledois:gap-6'>
                    <SelectDateTime
                        isOpen={isOpenEnd} setIsOpen={setIsOpenEnd}
                        date={endDate} setDate={setEndDate}
                        time={endTime} setTime={setEndTime} today={today} start={false} />
                </div>
                <div onClick={() => navigate('/map')} className='seis:rounded-[10px] bg-[var(--primary)] w-[3rem] h-[3rem] flex justify-center items-center cursor-pointer'>
                    <img src={SearchIcon} alt="icon" className='w-6 seis:w-8 invert' />
                </div>
            </div>
        </header>
    );
}

