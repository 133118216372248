import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

interface GaragesContextProps {
    garages: any[];
}

export const GaragesContext = createContext<GaragesContextProps>({ garages: []});

export const GaragesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [garages, setGarages] = useState<any[]>([]);

    useEffect(() => {
        const fetchGarages = async () => {
            try {
                let data;

                // Check if data is in session storage
                const storedData = sessionStorage.getItem('garages');
                if (storedData) {
                    data = JSON.parse(storedData);
                } else {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/garage/allActive` );
                    data = response.data.data;

                    // Store data in session storage
                    sessionStorage.setItem('garages', JSON.stringify(data));
                }

                setGarages(data);
            } catch (error) {
                console.error('Error fetching garages:', error);
            } 
        };

        fetchGarages();
    }, []);

    return (
        <GaragesContext.Provider value={{ garages }}>
            {children}
        </GaragesContext.Provider>
    );
};

export function useGarages() {
    const context = React.useContext(GaragesContext);
    if (!context) {
        throw new Error('GaragesContext is null, component must be used inside a GaragesProvider');
    }
    return context;
}