import Location from '../content/images/icons/location.png';
import Clock from '../content/images/icons/clock.png';
import Phone from '../content/images/icons/phone.png';

export default function Popup({ setPopup }: any) {
    return (
        <div className='absolute top-0 left-0 w-screen h-screen flex justify-center items-center bg-[rgba(.5,.5,.5,.3)] z-[99]'>
            <div className='w-full h-full quatro:h-auto quatro:w-[23rem] seis:w-[35rem] bg-[var(--white)] p-8 border-2 border-black quatro:rounded-[25px] flex flex-col gap-8 uppercase'>
                <div className='flex justify-between'>
                    <p className='font-garetBold seis:text-[1.2rem] text-[1rem]'>Como funciona?</p>
                    <p onClick={() => setPopup(false)} className='font-garetBold text-[1.5rem] seis:text-[2rem] cursor-pointer'>X</p>
                </div>
                <div className='flex justify-between gap-10'>
                    <div className='flex gap-4'>
                        <p className='font-garetBold text-[2rem] w-5'>1</p>
                        <div>
                            <p className='text-[.8rem] seis:text-[1rem] text-[var(--primary)] font-garetBold mb-2'>ENCONTRE O MELHOR ESTACIONAMENTO</p>
                            <p className='font-garetBold text-[.8rem] seis:text[1rem]'>PERTO DO SEU DESTINO</p>
                        </div>
                    </div>
                    <img src={Location} alt="icon" className='w-14 seis:w-24 object-cover' />
                </div>
                <div className='flex justify-between gap-10'>
                    <div className='flex gap-4'>
                        <p className='font-garetBold text-[2rem] w-5'>2</p>
                        <div >
                            <p className='text-[.8rem] seis:text-[1rem] text-[var(--primary)] font-garetBold mb-2 text-balance'>RESERVE SEU LUGAR A UM BOM PREÇO</p>
                            <p className='font-garetBold text-[.8rem] seis:text[1rem] text-balance'>E VOCÊ TAMBÉM ECONOMIZARÁ DANDO VOLTAS.</p>
                        </div>
                    </div>
                    <img src={Clock} alt="icon" className='h-14 seis:h-16 object-cover' />
                </div>
                <div className='flex justify-between gap-10'>
                    <div className='flex gap-4'>
                        <p className='font-garetBold text-[2rem] w-5'>3</p>
                        <div>
                            <p className='text-[.8rem] seis:text-[1rem] text-[var(--primary)] font-garetBold mb-2'>MOSTRE SUA RESERVA QUANDO CHEGAR AO ESTACIONAMENTO.</p>
                            <p className='font-garetBold text-[.8rem] seis:text[1rem]'>SE O ESTACIONAMENTO TIVER UM LEITOR DE PLACAS, NÃO SERÁ NECESSÁRIO.</p>
                        </div>
                    </div>
                    <img src={Phone} alt="icon" className='h-16 seis:h-20 object-cover' />
                </div>
            </div>
        </div>
    );
}

