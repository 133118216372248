
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PreviewCard from "../../components/PreviewCard";
import ServiceIcon from "../../components/ServiceIconMapper";
//carousel
import CarouselGarage from "../../components/Carousel";
//map
import "./preview.styles.scss";
import marker from "../../content/images/icons/icon-park.png"
import L, { LatLngExpression } from "leaflet";
import "leaflet-draw";
//icons
import { IoArrowBack } from "react-icons/io5";
import { BsClockFill } from "react-icons/bs";
import { TbInfoCircleFilled } from "react-icons/tb";
import { useGarages } from "../../contexts/GaragesContext";


interface Schedule {
    day: string;
    open: string;
    close: string;
}



export default function Preview() {
    const [imagesUrls, setImagesUrls] = useState([]);

    const { garageName } = useParams<{ garageName: string }>();
    const freeCancelRef = useRef(null);

    const mapRef = useRef<L.Map | L.LayerGroup<any> | null>(null);
    //garages examples for testing purposes

    const [address, setAddress] = useState("Portugal");
    const [addressCoords, setAddressCoords] = useState<[number, number]>([0, 0]);
    const [schedules, setSchedules] = useState<Schedule[]>([]);
    const [activeServices, setActiveServices] = useState<string[]>([]);
    const [contact, setContact] = useState("");
    const [email, setEmail] = useState("");
    const [numberChargers, setNumberChargers] = useState(0);
    const [garage, setGarage] = useState<any>(null);

    const garages = useGarages().garages;

    const icon = L.icon({
        iconUrl: marker,
        iconSize: [70, 70],
    });

    const displayChargers = (nChargers: number) => {
        if (nChargers > 1) {
            return `${nChargers} Caregadores disponiveis`;
        } else if (nChargers === 1) {
            return `${nChargers} Caregador disponivel`;
        } else {
            return `Sem Caregadores disponiveis`;
        }
    }


    useEffect(() => {
        // Find the garage with the name that matches `garageName`
        const garage = garages.find((garage: { name: string | undefined; }) => garage.name === garageName);
        if (garage) {
            setGarage(garage);
            setImagesUrls(garage.image);
            setAddressCoords([garage.address.latitude, garage.address.longitude])
            setAddress(garage.address.address);
            setContact(garage.phoneNumber);
            setSchedules(garage.schedule ? garage.schedule : []);
            setEmail(garage.email);
            setActiveServices(garage.services ? garage.services : []);
            if (garage.chargers)
                setNumberChargers(garage.chargers.length);
        }
    }, [garageName, garages]);



    useEffect(() => {


        if (mapRef.current) {
            return;
        }


        //setting up the map
        const center = [
            32.778760,
            -6.167488
        ];
        mapRef.current = L.map("map", { attributionControl: false, zoomControl: false, doubleClickZoom: false }).setView(center as LatLngExpression, 12);

        const map = mapRef.current;

        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        }).addTo(map);

    }, []);


    useEffect(() => {
        if (mapRef.current && mapRef.current instanceof L.Map) {
            if (addressCoords[0] !== 0 && addressCoords[1] !== 0) {
                mapRef.current.setView(addressCoords as LatLngExpression, 12);
                const marker = L.marker(addressCoords, { icon }).addTo(mapRef.current);
                marker.bindPopup(address);
            }
        }
    }, [addressCoords]);




    return (
        <div className='relative pt-12 gap-12 seis:px-[4vw] px-[8vw]'>
            <div className="py-10">
                <Link to={`/map`}>
                    <div className="flex text-lg font-extrabold cursor-pointer w-fit "><IoArrowBack title="Voltar atrás" size={28} /><p className="flex items-center">Voltar</p></div>
                </Link>
            </div>

            <div className="min-h-full gap-4 py-12 miledois:flex">
                <div className="w-full miledois:w-[70%]">
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-col">
                            <h1 className="text-xl font-semibold">Estacionamento {garageName}</h1>
                            <p className="text-sm font-semibold text-zinc-400">{address}</p>
                        </div>
                        <div className="flex justify-start">
                            <div className="flex flex-col text-xl text-blue-600">
                                <span>★★★★★</span>
                                <span className="text-sm text-center text-zinc-600">5 Opiniões</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full">
                        <CarouselGarage type={imagesUrls?.length > 3 ? "garageImagesPreview" : "garageImages"} multiple={imagesUrls!.length > 1}>
                            {imagesUrls?.map((img, index) => (
                                <div key={index} className="w-[80%] cinco:w-[90%] h-[15rem] cinco:h-[15rem] bg-cover bg-center bg-no-repeat rounded-3xl" style={{ backgroundImage: `url(${img})` }}>
                                </div>
                            ))}
                        </CarouselGarage>
                    </div>
                    <div className="pt-8 rounded-lg 2xl:pr-8">
                        <div className="justify-between pb-10 mile100:flex">
                            <div className="pt-8 mile100:pt-0 mile100:flex mile100:flex-col">
                                <div className="flex gap-2 px-2 py-1 bg-yellow-300 rounded-lg max-w-fit">
                                    <span>🗲</span>
                                    <p className="font-semibold text-center text-black">{displayChargers(numberChargers)}</p>
                                </div>
                            </div>
                        </div>

                        <div className="grid gap-6 cinco:grid-cols-2 oito:grid-cols-3 mile200:flex mile200:justify-between">
                            <div className="flex flex-col">
                                <div className="flex items-center justify-between w-1/2 pb-4">
                                    <h2 className="text-lg font-semibold">Serviços</h2>
                                </div>
                                {activeServices?.length > 0 ? <div className="flex flex-row pt-10">

                                    <div className={`${activeServices.length && "border-r"} border-zinc-400 pr-2 flex items-center`}>
                                        <BsClockFill title="Horários" color="#bfc7ec" size={30} />
                                    </div>
                                    <div className={`grid ${activeServices.length < 4 ? "grid-flow-col auto-cols-max " : "grid-cols-4 "} gap-2 pl-2`}>
                                        {activeServices.map((item, index) => (
                                            <ServiceIcon key={index} serviceName={item} />
                                        ))}
                                    </div>

                                </div> : <p className="text-sm">Sem serviços associados</p>}
                                <div className="pt-4 pb-6">
                                    {activeServices.length > 0 && <hr className="w-full border-t border-zinc-400 max-w-[11.5rem]" />}
                                </div>
                                <div className="text-sm">
                                    <h2 className="pb-4 font-semibold">Contactos</h2>
                                    <p>{contact}</p>
                                    <p>{email}</p>
                                </div>
                            </div>
                            <div className="rounded-lg">
                                <div className="flex items-center justify-between w-1/2 pb-4">
                                    <h2 className="text-lg font-semibold">Horário</h2>
                                </div>
                                {schedules!.length > 0 ? schedules.map((item, index) => (
                                    <div key={index} className="flex gap-2 py-2 cinco:gap-4">
                                        <p className="py-2 text-center bg-transparent border border-black border-solid rounded-full w-28" >{item.day}</p>
                                        <p className="w-16 py-2 text-center bg-transparent border border-black border-solid rounded-full" >{item.open}</p>
                                        <p className="w-16 py-2 text-center bg-transparent border border-black border-solid rounded-full" >{item.close}</p>
                                    </div>
                                )) : <p className="text-sm">Sem horários associados</p>}
                            </div>
                            <div className="previewMap oito:col-span-1">
                                <h2 className="pb-2 text-lg font-semibold">Local</h2>
                                <div id="map" />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="w-full pt-10 miledois:pt-0 miledois:w-[30%]">
                    {garage && <PreviewCard garage={garage} refTo={freeCancelRef} schedule={schedules}/>}
                </div>
            </div>
            <div className="pb-10">
                <div className="px-8 py-8 bg-zinc-200 rounded-2xl">
                    <div className="flex gap-4">
                        <TbInfoCircleFilled title="Info" size={32} color="grey" />
                        <p ref={freeCancelRef} className="flex items-center font-bold text-md">Cancelamento gratuito</p>
                    </div>
                    <div className="pt-6">
                        <p className="font-bold text-md">Porque é que tenho mais tempo?</p>
                        <p className="font-bold text-md">Cancelamento é grátis.</p>
                    </div>
                </div>
            </div>
        </div>
    );

}

