import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import Loading from '../components/loading';

export default function Terms() {
    const [text, setText] = useState<any>(null);

    useEffect(() => {
        async function fetchTexts() {
            try {
                let docRef = doc(db, 'website', 'terms');
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setText(data.terms);
                }
            } catch (error) {
                console.error("Error getting document: ", error);
            }
        }
        fetchTexts();
    }, []);

    if (!text) {
        return (
            <Loading />
        )
    }

    return (
        <div className='pt-[8rem] pb-[4rem] px-[8vw]'>
            <div>
                <p className='font-garetBold text-[var(--primary)] text-[2rem] mb-8'>Termos e Condições</p>
                <p className='whitespace-pre-wrap'>{text}</p>
            </div>
        </div>
    );
}

