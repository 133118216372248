import './loader.scss';
import parking from '../../content/images/icons/carParking2.png';
const Loading = ({ isOpen }: { isOpen: boolean }) => {

  return (
    <>
      {isOpen && (
        <div className="loader-overlay">
          <div className="loader bg-[var(--primary)]">
            <img className='rounded-full px-2 py-2' src={parking} alt="loading" />
          </div>
        </div>
      )}
    </>
  );
}

export default Loading;