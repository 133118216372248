import {  useEffect, useRef, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '../../content/images/icons/search.png';
import CloseIcon from '../../content/images/icons/close.png';
import Card from './mapCard';
import { FaEraser } from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOMServer from 'react-dom/server';
import "./map.styles.scss";
import L, { LatLngExpression } from "leaflet";
import "leaflet-draw";
import marker from "../../content/images/icons/icon-park.png"
import { Geocoder } from 'leaflet-control-geocoder';
import Loading from '../../components/loading/loading';
import { useDateTimeContext } from '../../contexts/DateTimeContext';
import { useVehicleContext } from '../../contexts/VehicleContext';
import VehicleOptionsComponent from '../../components/VehicleOption';
import { useGarages } from '../../contexts/GaragesContext';
import SelectDateTime from '../home/components/DateTimePicker';

export default function Map() {
    const garages = useGarages().garages;

    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [address, setAddress] = useState('');
    const [cards, setCards] = useState<any[]>([]);
    const [filteredCards, setFilteredCards] = useState<any[]>([]);
    const [viewCards, setViewCards] = useState<any[]>([]);
    const [selectedPark, setSelectedPark] = useState<any>(null);
    const mapRef = useRef<L.Map | L.LayerGroup<any> | null>(null);
    const circleRef = useRef<L.Circle | null>(null);
    const [hasCircle, setHasCircle] = useState(false);
    const [onChange, setOnChange] = useState(false);
    const markerGroupRef = useRef<L.LayerGroup | null>(null);
    const [circleRadius, setCircleRadius] = useState(1000); // initial radius
    const [loading, setLoading] = useState(true);
    const [isOpenStart, setIsOpenStart] = useState(false);
    const [isOpenEnd, setIsOpenEnd] = useState(false);

    const { vehicleType, setVehicleType } = useVehicleContext();
    const [isOpenVehicleType, setOpenVehicleType] = useState(false);

    const vehicleOptions = VehicleOptionsComponent().vehicleOptions;


    const today = new Date();
    const time = "09:00"
    const { startDate, setStartDate, endDate, setEndDate, startTime, setStartTime, endTime, setEndTime } = useDateTimeContext();


    const [searching, setSearching] = useState(false)

    const geocode = new Geocoder({
        collapsed: true,
        defaultMarkGeocode: false
    });
    const icon = L.icon({
        iconUrl: marker,
        iconSize: [50, 50],
    });


    useEffect(() => {
        setCards(garages);
        setFilteredCards(garages);
        setViewCards(garages);
        if(garages.length > 0)
            setLoading(false);
    }, [garages]);

    useEffect(() => {
        if (selectedPark && selectedPark.address) {
            const newLng = selectedPark.address.longitude;
            const newLat = selectedPark.address.latitude;
            if (!isNaN(newLng) && !isNaN(newLat)) {
                const map = mapRef.current as L.Map;
                if (map) {
                    map.setView([newLat, newLng] as LatLngExpression, 12);
                }
            }
        }
    }, [selectedPark]);


    const popUpContent = (card: any) => {
        const content = (
            <div >
                <p className="text-base font-black">{card.name}</p>
                <div className="flex justify-center items-center">
                    <img src={card.image} alt="park" className="w-20 h-20" />
                </div>
                <p className="flex">
                    <div className='text-sm font-black '>Total de lugares:</div>
                    <div className='text-xs pl-4'>{card.totalSpots}</div>
                </p>
                <p className="text-sm font-black pl-4"></p>
                <div className="flex justify-center">
                    <button id={`navigate-${card.name}`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Ver mais
                    </button>
                </div>
            </div>
        );

        // Convert the React element to an HTML string
        return ReactDOMServer.renderToString(content);
    };

    useEffect(() => {
        const map = mapRef.current as L.Map;
        if (map) {
            // Clear the marker group
            if (!markerGroupRef.current) {
                markerGroupRef.current = L.layerGroup().addTo(map);
            }
            markerGroupRef.current.clearLayers();
            let cardsOnCircle: any[] = [];
            filteredCards?.forEach((card: any, index: any) => {
                if (card.address) {
                    const pos = [card.address?.latitude, card.address?.longitude];
                    const marker = L.marker(pos as LatLngExpression, { icon });
                    marker.bindPopup(popUpContent(card));
                    marker.on('popupopen', function () {
                        const navigateButton = document.getElementById(`navigate-${card.name}`);
                        navigateButton?.addEventListener('click', () => navigate(`/preview/${card.name}`));
                    });
                    // Add the marker to the group if it's within the circle
                    if (circleRef.current) {
                        const circleCenter = circleRef.current.getLatLng();
                        const markerPosition = marker.getLatLng();
                        if (circleCenter.distanceTo(markerPosition) <= circleRef.current.getRadius()) {
                            markerGroupRef.current!.addLayer(marker);
                            cardsOnCircle.push(card);
                        }
                    } else {
                        markerGroupRef.current!.addLayer(marker);
                        cardsOnCircle.push(card);
                    }
                }
            });
            const newFilteredCards = filteredCards.filter((card) => cardsOnCircle.includes(card));
            setViewCards(newFilteredCards);
            // Add the marker group to the map
            markerGroupRef.current!.addTo(map);
        }
    }, [filteredCards, mapRef, onChange]);

    useEffect(() => {
        if (mapRef.current) {
            return;
        }
        //setting up the map
        const center = [38.736946, -9.142685];
        mapRef.current = L.map("map", {
            attributionControl: false,
            zoomControl: false,
            doubleClickZoom: false // disable zoom on double click
        }).setView(center as LatLngExpression, 12);


        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        }).addTo(mapRef.current);



    }, []);


    const handleSearchSubmit = (e: any) => {
        e.preventDefault();
        setAddress(search);
        setSearching(true);
        const capitalizeFirstLetter = (string: String) => string.charAt(0).toUpperCase() + string.slice(1);

        const startDay = capitalizeFirstLetter(startDate.toLocaleDateString('pt-PT', { weekday: 'short' }));
        const endDay = capitalizeFirstLetter(endDate.toLocaleDateString('pt-PT', { weekday: 'short' }));
        let filteredCards = [];
        for (let i = 0; i < cards.length; i++) {
            const card = cards[i];
            if (card.schedule) {
                for (let j = 0; j < card.schedule.length; j++) {
                    const schedule = card.schedule[j];
                    if (schedule.day === startDay && schedule.day === endDay) {
                        if (startTime >= schedule.open && endTime <= schedule.close) {
                            filteredCards.push(card)

                            break; // Breaks out of the inner loop
                        }
                    }
                }
            }
        }
        setFilteredCards(filteredCards);
        setViewCards(filteredCards);
    }

    const removeCircle = () => {
        if (circleRef.current) {
            mapRef.current?.removeLayer(circleRef.current);
            circleRef.current = null;
            setHasCircle(false);
            setOnChange(!onChange);
        }
    }

    useEffect(() => {
        const map = mapRef.current as L.Map;

        if (address === '') {
            removeCircle();
            return;
        }

        if (geocode.options.geocoder) {
            setLoading(true);
            geocode.options.geocoder.geocode(address, (results) => {
                if (results.length === 0) {
                    toast.error('Endereço não encontrado');
                    setLoading(false); // set loading to false if no results
                    return;
                }
                const latlng = results[0].center;
                map.setView(latlng, 13);
                removeCircle()
                circleRef.current = L.circle(latlng, {
                    radius: circleRadius, // value in meters
                    color: '#5c5eef',
                    fillColor: '#5c5eef',
                    fillOpacity: 0.1
                }).addTo(map);
                setOnChange(!onChange);
                setHasCircle(true);
                setLoading(false); // set loading to false after geocode search has completed
            });
        }
    }, [address]);

    useEffect(() => {
        if (circleRef.current) {
            circleRef.current.setRadius(circleRadius);
            setOnChange(!onChange);
        }
    }, [circleRadius]);

    useEffect(() => {
        const filteredCards = filterByVehicleType(vehicleType);
        setFilteredCards(filteredCards);
        setViewCards(filteredCards);
    }, [vehicleType, cards]);

    const filterByVehicleType = (vehicleType: string) => {
        let filteredCards = [];
        for (let i = 0; i < cards.length; i++) {
            const card = cards[i];
            if (card.price) {
                for (let j = 0; j < card.price.length; j++) {
                    if (card.price[j].type === vehicleType) {
                        filteredCards.push(card)
                        break; // Breaks out of the inner loop
                    }
                }
            }
        }
        return filteredCards;

    }

    const resetSearch = () => {
        const filteredCards = filterByVehicleType(vehicleType);
        setFilteredCards(filteredCards);
        setViewCards(filteredCards);
        setSearch("");
        setSearching(false);
        setStartDate(today);
        setEndDate(today);
        setStartTime(time);
        setEndTime(time);
        removeCircle();
    }

    return (
        <div className='relative pt-[8rem] flex flex-col items-center gap-12'>
            <Toaster />
            <div className='w-[80%] bg-[var(--white)] px-4 py-2 mil:py-6 rounded-[10px] mil:rounded-[25px] gap-2 flex justify-between items-center z-[2]'>
                <form action="/search" className="w-full max-w-lg seis:w-[30%]" onSubmit={handleSearchSubmit}>
                    <div className="relative">
                        <input type="text" placeholder='Endereço, local...' name="search" className="w-full bg-[transparent]  mil:border-r-2 border-[grey] dark:text-gray-800 outline-none" onChange={(e) => setSearch(e.target.value)} value={search} />
                        <button type="submit">
                        </button>
                    </div>
                </form>
                <div className='hidden mil:flex gap-2 miledois:gap-6'>
                    <SelectDateTime
                        isOpen={isOpenStart} setIsOpen={setIsOpenStart}
                        date={startDate} setDate={setStartDate}
                        time={startTime} setTime={setStartTime} today={today} start={true} />
                </div>
                <div className='hidden mil:flex gap-2 miledois:gap-6'>
                    <SelectDateTime
                        isOpen={isOpenEnd} setIsOpen={setIsOpenEnd}
                        date={endDate} setDate={setEndDate}
                        time={endTime} setTime={setEndTime} today={today} start={false} />
                </div>
                <div onClick={() => setOpenVehicleType(!isOpenVehicleType)} className='seis:rounded-[10px] bg-white w-[3rem] h-[3rem] flex justify-center items-center cursor-pointer relative'>
                    {VehicleOptionsComponent().findIconByValue(vehicleType)}
                    {isOpenVehicleType && <div className={`flex gap-1 flex-col absolute top-[110%] bg-white rounded-xl transition-opacity ease-in-out duration-200 border border-solid border-zinc-400 p-2`}>
                        {vehicleOptions.map((vehicle, index) => {
                            return (
                                <button
                                    key={index}
                                    onClick={() => {
                                        setVehicleType(vehicle.value);
                                        setOpenVehicleType(false)
                                    }}
                                    className={`${vehicleType === vehicle.value ? "bg-blue-100  border-blue-500 text-blue-500" : "bg-white quatro:bg-transparent border-white text-zinc-400"} transition-all duration-500 rounded-lg  border border-solid p-1`}>
                                    {vehicle.value}
                                </button>
                            )
                        }
                        )}
                    </div>}
                </div>
                <div className='seis:rounded-[10px] bg-[var(--primary)] w-[3rem] h-[3rem] flex justify-center items-center cursor-pointer'>
                    {!searching ? <img src={SearchIcon} onClick={handleSearchSubmit} alt="icon" className='w-6 seis:w-8 invert' />
                        : <img src={CloseIcon} onClick={resetSearch} alt="icon" className='w-6 seis:w-8 invert' />}
                </div>


            </div>
            <div className=' mil:hidden flex gap-10 flex-col cinco:flex-row px-4'>
                <div >
                    <SelectDateTime
                        isOpen={isOpenStart} setIsOpen={setIsOpenStart}
                        date={startDate} setDate={setStartDate}
                        time={startTime} setTime={setStartTime} today={today} start={true} />
                </div>
                <div >
                    <SelectDateTime
                        isOpen={isOpenEnd} setIsOpen={setIsOpenEnd}
                        date={endDate} setDate={setEndDate}
                        time={endTime} setTime={setEndTime} today={today} start={false} />
                </div>
            </div>
            <div className="w-full px-2 flex mil:flex-row flex-col gap-2 mil:h-[80vh]">
                <div className="flex flex-col gap-2 w-full mil:w-[26rem] h-[30rem] mil:h-full overflow-y-auto py-2 shrink-0 rounded-[20px]">
                    {
                        !viewCards ? <CircularProgress color="inherit" /> :
                            viewCards.length !== 0 ? viewCards.map((ele: any, index: number) => {
                                if (ele.address !== undefined) {
                                    return (
                                        <Card
                                            key={index}
                                            park={ele}
                                            selectedPark={selectedPark}
                                            setSelectedPark={setSelectedPark}
                                        />
                                    )
                                } else {
                                    return <></>
                                }
                            })
                                :
                                <p className='text-center w-full'>Nenhum resultado</p>
                    }
                </div>
                <div className="w-full pb-2">
                    <div className="relative homeMap w-full h-full">
                        <div id="map" />
                        {hasCircle &&
                            <div className='absolute top-2 right-2 cursor-pointer flex flex-col gap-2  bg-white px-2 py-2 rounded-xl '>
                                <div className='flex justify-center border-b pb-2 border-black' onClick={removeCircle}>
                                    <FaEraser title='Apagar Círculo' size={32} color='#5c5eef' />
                                </div>
                                <div>
                                    <p className='text-center text-[#5c5eef]'>Raio</p>
                                    <input type="range" min="1000" max="10000" step="100" value={circleRadius} className="slider" onChange={(e) => setCircleRadius(Number(e.target.value))} />
                                    <p className='text-sm text-center'>{circleRadius} metros</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Loading isOpen={loading} />
        </div>
    );
}


