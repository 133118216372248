
import "react-datepicker/dist/react-datepicker.css";
import { useDateTimeContext } from "../../contexts/DateTimeContext";
import { useVehicleContext } from "../../contexts/VehicleContext";




const CheckOutCard = ({ garage }: { garage: any }) => {

    const { startDate, endDate, startTime, endTime} = useDateTimeContext();


    function getDifferenceInDays(date1: Date, date2: Date): number {
        const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
        const differenceInMilliseconds = date2.getTime() - date1.getTime();
        const differenceInDays = differenceInMilliseconds / oneDayInMilliseconds;
        return Math.floor(differenceInDays); // Use Math.floor to get a whole number of days
    }

    function calculateTimeDifference(startDate: Date, endDate: Date, startTime: string, endTime: string) {
        const [startHours, startMinutes] = startTime.split(':').map(Number);
        const [endHours, endMinutes] = endTime.split(':').map(Number);
        const startDateTime = new Date(startDate);
        const endDateTime = new Date(endDate);
        startDateTime.setHours(startHours, startMinutes);
        endDateTime.setHours(endHours, endMinutes);
        const diffInMs = endDateTime.getTime() - startDateTime.getTime();
        let diffInHours = diffInMs / (1000 * 60 * 60);
    
        if (diffInHours >= 24) {
            const days = Math.floor(diffInHours / 24);
            const hours = diffInHours % 24;
            return `${days} dia(s) e ${hours} hora(s)`;
        }
    
        return `${diffInHours} hora(s)`;
    }

    function getPrice(garage: any): number {
        const prices = garage.price;
        if (prices) {
            const price = prices.find((price: { type: string; value: Number }) => price.type === vehicleType);
            return price.value;
        }
        return 0;
    }

    const { vehicleType } = useVehicleContext();

    return (
        <div className=" rounded-lg shadow-2xl p-4">
            <h2 className="text-lg font-semibold text-zinc-800 pb-2">Detalhes da reserva</h2>
            <div className="border border-zinc-400 rounded-2xl px-2 py-4">
                <div className="flex justify-between items-center pb-2 gap-4">
                    <div>
                        <h3 className="text-zinc-700 font-bold text-xl">{garage?.name}</h3>
                        <h3 className="text-zinc-400 font-bold text-xs">{garage?.address.address}</h3>
                    </div>
                    <div className="text-right">
                        <span className="text-3xl font-bold text-red-500">{garage ? getPrice(garage) : 0} €</span>
                        {getDifferenceInDays(startDate, endDate) >= 0 && <p className="text-zinc-400 text-sm">
                            Reserva com {calculateTimeDifference(startDate, endDate, startTime, endTime)} de duração
                        </p>}
                    </div>
                </div>
                <hr className="w-full border-t border-zinc-400" />
                <div className="pt-4">
                    <div className="flex justify-between pb-2">
                        <div className="w-1/2">
                            <div className="flex flex-col">
                                <p className='text-[1rem] flex items-center text-center'>
                                    {startDate.toLocaleDateString('pt-PT', { weekday: 'short', day: 'numeric', month: 'long' })}
                                </p>
                                <p className='text-[1rem] flex items-center text-center'>
                                    {startTime}
                                </p>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <div className="flex flex-col">
                                <p className='text-[1rem] flex items-center text-center' >
                                    {endDate.toLocaleDateString('pt-PT', { weekday: 'short', day: 'numeric', month: 'long' })}
                                </p>
                                <p className='text-[1rem] flex items-center text-center' >
                                    {endTime}
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr className="w-full border-t border-zinc-400" />
                    <div className="pt-4 grid grid-cols-1 seis:grid-cols-2 gap-2">
                        <div>
                            <p className="text-zinc-600">Veículo</p>
                            <p className="font-semibold text-zinc-800">{vehicleType}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default CheckOutCard;