import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

import Loading from '../../components/loading';
import Form from './components/form';
import Benefits from './components/benefits';

export default function Partners() {
    const [ramoOptions, setRamoOptions] = useState<any>(null);
    const [tipoOptions, setTipoOptions] = useState<any>(null);

    useEffect(() => {
        async function fetchTexts() {
            try {
                let docRef = doc(db, 'website', 'partnersForm');
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setRamoOptions(data.ramos);
                    setTipoOptions(data.tipos);
                }
            } catch (error) {
                console.error("Error getting document: ", error);
            }
        }
        fetchTexts();
    }, []);

    if (!ramoOptions || !tipoOptions) {
        return (
            <Loading />
        )
    }

    return (
        <div className='w-full pt-[5rem] px-[8vw]'>
            <Form ramoOptions={ramoOptions} tipoOptions={tipoOptions}/>
            <Benefits />
        </div>
    );
}

