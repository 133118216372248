import React, { useEffect, useState } from 'react';
import Input from '../../../components/input';
import Select from '../../../components/select';
import Stepper from '@mui/material/Stepper';
import { Step, StepLabel } from '@mui/material';
import { useUserContext } from '../../../contexts/userContext';
import { GoogleSignInButton } from '../../../components/googleIcon';
import CheckboxWithLabel from '../../../components/checkBox';
import axios from 'axios';
import Loading from '../../../components/loading/loading';

interface FormPaperProps {
  children: React.ReactNode;
  title: any;
}

export const FormPaper: React.FC<FormPaperProps> = ({ children, title }) => {
  return (
    <div className='w-full h-96 flex flex-col justify-between'>
      <h1 className=' text-xl'>{title}</h1>
      {children}
    </div>
  );
}

// Definição dos tipos de props
interface FormProps {
  next: any;
  lastStep: React.ReactNode;
}

interface FormData {
  type: string;
  name: string;
  lastName: string;
  city: string;
  prefixo: string;
  contact: string;
  companyName?: string;
  companySize?: string;
  email: string;
  password: string;
  terms: boolean | string;
  newsletter: boolean;
}

const SimpleSignup: React.FC<FormProps> = ({ next, lastStep }) => {
  const { setUser } = useUserContext();
  const [type, setType] = useState<string>('company');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [prefixo, setPrefixo] = useState<string>('');
  const [contact, setContact] = useState<string>('');
  const [isNotCompany, setisNotCompany] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState<string>('');
  const [companySize, setCompanySize] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [terms, setTerms] = useState<boolean>(false);
  const [newsletter, setNewsletter] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState<Partial<FormData>>({});

  const handleNextStep = () => setActiveStep(Math.min(activeStep + 1, 3));
  const handlePrevStep = () => setActiveStep(Math.max(activeStep - 1, 0));



  useEffect(() => {
    const updateType = () => {
      !isNotCompany ? setType('agent') : setType('company');
    };
    updateType();
    console.log(type)
  }, [isNotCompany]);
  const validateStep = () => {
    const newErrors: Partial<FormData> = {};



    if (activeStep === 0) {
      if (!firstName) newErrors.name = 'Nome é obrigatório';
      if (!lastName) newErrors.lastName = 'Apelido é obrigatório';

      if (!city) newErrors.city = 'Cidade é obrigatória';

      if (!prefixo) newErrors.prefixo = 'Prefixo é obrigatório';
      if (!contact) newErrors.contact = 'Telefone é obrigatório';
    } else if (activeStep === 1 && !isNotCompany) {
      if (!companyName) newErrors.companyName = 'Nome da empresa é obrigatório';
      if (!companySize) newErrors.companySize = 'Tamanho da empresa é obrigatório';
    } else if (activeStep === 2) {
      if (!email) newErrors.email = 'Email é obrigatório';
      if (!password) newErrors.password = 'Senha é obrigatória';
      if (!terms) newErrors.terms = 'Aceitar os termos é obrigatório';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      handleNextStep();
    }
  };

  const signup = async () => {
    if (!validateStep()) return;

    let compData = {};
    if (!isNotCompany) {
      compData = {
        companyName,
        companySize,
      };
    }

    const formData = {
      type,
      firstName,
      lastName,
      city,
      prefixo,
      contact,
      ...compData,
      email,
      password,
      terms,
      newsletter,
    };


    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/user/signup`;
      setIsLoading(true)
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });


      if (response.data.condition !== 'success') {
        throw new Error(response.data.error);
      }

      const res = response.data;
      setUser({
        name: res.data.name,
        email: res.data.email,
        token: res.token.accessToken,
      });
      console.log(res)

      await next();
      setIsLoading(false)
      handleNextStep();
    } catch (error) {
      alert(error);
      setIsLoading(false)

      console.error('Erro no signup:', error);
    }
  };

  return (

    <div className='p-16 px-[10%] flex flex-col items-center gap-10 w-full'>
      <div className='w-full min-w-[450px] max-w-[800px]  flex flex-col gap-4'>
        {activeStep < 3 && <>
          <h1 className='text-[1.2rem] quatro:text-[2rem] text-center'>Signup</h1>

          <Stepper alternativeLabel activeStep={activeStep} sx={{
            '.Mui-active': { color: '#5d5fef' },
            '.Mui-completed': {
              '.MuiStepLabel-alternativeLabel': { color: '#000000' },
              color: '#5d5fef',
              opacity: '70%'
            }
          }}>
            <Step>
              <StepLabel>Dados pessoais</StepLabel>
            </Step>
            <Step>
              <StepLabel>Dados da empresa</StepLabel>
            </Step>
            <Step>
              <StepLabel>Dados de login</StepLabel>
            </Step>
          </Stepper>

        </>}
        {activeStep === 0 && (
          <FormPaper title={''}>
            <div className='w-full flex flex-col gap-4'>
              <div className=' flex gap-10'>
                <Input label='Nome' value={firstName} setValue={setFirstName} error={errors.name} />
                <Input label='Apelido' value={lastName} setValue={setLastName} error={errors.lastName} />
              </div>

              <Input label='Cidade de residência' value={city} setValue={setCity} error={errors.city} />
              <div className=' flex gap-10'>

                <Input label='Prefixo' value={prefixo} setValue={setPrefixo} error={errors.prefixo} />
                <Input label='Telefone' value={contact} setValue={setContact} error={errors.contact} />
              </div>
            </div>
            <p className='text-center text-[.8rem] text-balance'>Tem conta na <span className='uppercase font-garetBold'>Multipark</span>? Inicie sessão <a href="/login" className='underline'>aqui</a></p>

          </FormPaper>
        )}

        {activeStep === 1 && (

          <FormPaper title={''}>
            <div className='w-full flex flex-col gap-4'>
              <div className={`${isNotCompany && "opacity-50 pointer-events-none"} flex flex-col gap-4`}>
                <Input label='Nome da empresa' value={companyName} setValue={setCompanyName} error={errors.companyName} />
                <Select label='Tamanho da empresa' value={companySize} setValue={setCompanySize} list={['Pequena', 'Média', 'Grande']} error={errors.companySize} />
              </div>
              <CheckboxWithLabel
                label="Não desejo me cadastrar como empresa"
                checked={isNotCompany}
                onChange={() => setisNotCompany(!isNotCompany)}
              />

            </div>
            <p className='text-center text-[.8rem] text-balance'>Tem conta na <span className='uppercase font-garetBold'>Multipark</span>? Inicie sessão <a href="/login" className='underline'>aqui</a></p>

          </FormPaper>
        )}

        {activeStep === 2 && (
          <FormPaper title={''}>
            <div className='w-full flex flex-col gap-4 items-center'>
              <Input label='Email' value={email} setValue={setEmail} type='email' error={errors.email} />
              <Input label='Password' value={password} setValue={setPassword} type='password' error={errors.password} />
              {/* <span className=' mx-auto'>ou</span>
              <GoogleSignInButton onClick={() => { }}></GoogleSignInButton> */}
              <div className=' w-full'>
                <CheckboxWithLabel
                  label="Eu concordo com os Termos e Condições"
                  checked={terms}
                  onChange={() => setTerms(!terms)}
                  error={errors.terms ? errors.terms.toString() : undefined}
                />
                <CheckboxWithLabel
                  label="Quero manter-me a par das novidades da Multipark"
                  checked={newsletter}
                  onChange={() => setNewsletter(!newsletter)}
                />
              </div>

            </div>

          </FormPaper>
        )}

        {activeStep === 3 && lastStep}
      </div>

      <div className='w-full flex justify-between flex-row-reverse items-center gap-4'>
        {activeStep < 3 && (
          <div className='w-full flex justify-between flex-row-reverse'>
            {activeStep > 1 ? (
              <button onClick={signup} className='rounded-full bg-[var(--primary)] px-4 py-2 text-white'>Registrar</button>
            ) : (
              <button onClick={handleNext} className='rounded-full bg-[var(--primary)] px-4 py-2 text-white'>Próximo</button>
            )}
          </div>
        )}
        {activeStep > 0 && activeStep < 3 && (
          <button onClick={handlePrevStep} className='rounded-full bg-gray-300 px-4 py-2 text-black'>Voltar</button>
        )}
      </div>
      <Loading isOpen={isLoading}></Loading>
    </div>
  );
};

export default SimpleSignup;


