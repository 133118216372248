import React, { useState } from 'react';

import Rentabilidade from '../../../content/images/icons/cashStock.png';
import Security1 from '../../../content/images/icons/security.png';
import Security2 from '../../../content/images/icons/camara.png';
import Custos from '../../../content/images/icons/cash-2.png';
import Dashboard from '../../../content/images/partners/dashboard-clients.png';
import BottomImage from '../../../content/images/partners/partners.png';

export default function Benefits() {

    const [section, setSection] = useState(1);

    function SectionSelected({ ...props }) {
        return (
            <div className='services-section flex flex-col gap-8 seis:w-[27rem]' style={{ display: section === props.index ? 'flex' : 'none' }}>
                <p className='text-[1.5rem] seis:text-[2.5rem] font-garetBold text-balance'>{props.title}</p>
                <p className='w-full mil:w-[70%] text-[.9rem] text-balance'>{props.text}</p>
                <div className='flex flex-col gap-[3rem]'>
                    <div className='flex gap-4 items-center'>
                        <div className='flex flex-col w-[17rem]'>
                            <p className='font-garetBold text-[1.2rem]'>{props.data1}</p>
                            <p className='font-garetBold text-[1.2rem]'>{props.info1}</p>
                        </div>
                        <img src={props.image1} alt="icon" className='w-[3rem] quatro:w-[5rem]' />
                    </div>
                    <div className='flex gap-4 items-center'>
                        <div className='flex flex-col w-[17rem]'>
                            <p className='font-garetBold text-[1.2rem]'>{props.data2}</p>
                            <p className='font-garetBold text-[1.2rem]'>{props.info2}</p>
                        </div>
                        <img src={props.image2} alt="icon" className='w-[3rem] quatro:w-[5rem]' />
                    </div>
                </div>
                {/* <img src="" alt="icon" className='' /> */}
            </div>
        )
    }

    return (
        <div className='relative w-full h-full flex flex-col gap-16 mil:pt-[5rem]'>
            <h3 className='font-garetBold text-[1.7rem] quatro:text-[2rem] text-center uppercase'>Benefits</h3>
            <div className='w-full flex mil:flex-row flex-col items-center mil:items-start mil:justify-center mil:gap-[8vw] pb-[10rem] mil:pb-[15rem]'>
                <div className='w-full mil:w-[12rem] grid grid-cols-2 seis:flex flex-row mil:flex-col mil:gap-8 seis:gap-2 gap-4 uppercase'>
                    <button onClick={() => setSection(1)} className={`w-full h-[7rem] px-4 quatro:px-10 rounded-[20px] text-[.7rem] quatro:text-[.8rem] mil:text-[1rem] font-garetBold uppercase ${section === 1 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}>Money</button>
                    <button onClick={() => setSection(2)} className={`w-full h-[7rem] px-4 quatro:px-10 rounded-[20px] text-[.7rem] quatro:text-[.8rem] mil:text-[1rem] font-garetBold uppercase ${section === 2 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}>Security</button>
                    <button onClick={() => setSection(3)} className={`w-full h-[7rem] px-4 quatro:px-10 rounded-[20px] text-[.7rem] quatro:text-[.8rem] mil:text-[1rem] font-garetBold uppercase ${section === 3 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)] text-black'}`}>Dashboard</button>
                </div>
                <SectionSelected
                    image1={Rentabilidade}
                    data1='+30%'
                    info1='Rentabilidade'
                    image2={Custos}
                    data2='+75% Redução'
                    info2='de Custos'
                    index={1}
                />
                <SectionSelected
                    image1={Security1}
                    data1='2% Comission'
                    info1='Security payment'
                    image2={Security2}
                    data2='24H security'
                    info2='cameras by Securitas'
                    index={2}
                />
                <div className={`services-section ${section !== 3 && 'hidden'} flex h-full gap-[4rem] justify-center items-center`}>
                    <img src={Dashboard} alt="dashboard" className='w-[27rem] place-self-center' />
                </div>
            </div>
            <img src={BottomImage} alt="bg" className='absolute bottom-0 right-[-8vw] w-[23vw] hidden oito:flex' />
        </div>
    );
}

