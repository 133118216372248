import { TbInfoCircleFilled } from "react-icons/tb";
import { useDateTimeContext } from "../contexts/DateTimeContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { IoIosWarning } from "react-icons/io";
import { RefObject } from 'react';
import { useVehicleContext } from "../contexts/VehicleContext";
import { useNavigate } from "react-router-dom";

interface Schedule {
    day: string;
    open: string;
    close: string;
}



const PreviewCard = ({ schedule, refTo, garage }: { schedule: Schedule[], refTo: RefObject<HTMLDivElement>, garage: any }) => {
    const navigate = useNavigate();

    const { startDate, setStartDate, endDate, setEndDate, startTime, setStartTime, endTime, setEndTime } = useDateTimeContext();
    const [isOpenStart, setIsOpenStart] = useState(false);
    const [isOpenEnd, setIsOpenEnd] = useState(false);
    const today = new Date();
    const dayNameToNumber = (dayName: string) => {
        const days = ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'];
        return days.indexOf(dayName.toLowerCase());
    }

    function isSameDay(date1: Date, date2: Date) {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    function getDifferenceInDays(date1: Date, date2: Date): number {
        const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
        const differenceInMilliseconds = date2.getTime() - date1.getTime();
        const differenceInDays = differenceInMilliseconds / oneDayInMilliseconds;
        return Math.floor(differenceInDays); // Use Math.floor to get a whole number of days
    }

    function getPrice(garage: any): number {
        const prices = garage.price;
        if(prices){
            const price = prices.find((price: { type: string; value: Number }) => price.type === vehicleType);
            return price.value;
        }
        return 0;
    }

    const { vehicleType } = useVehicleContext();

    useEffect(() => {

        if (schedule) {
            if (!isSameDay(startDate, today) && !isSameDay(endDate, today) && getDifferenceInDays(startDate, endDate) >= 0){
                const open = schedule.find(s => s.day.toLocaleLowerCase() === startDate.toLocaleDateString('pt-PT', { weekday: 'short' }))?.open;
                const close = schedule.find(s => s.day.toLocaleLowerCase() === endDate.toLocaleDateString('pt-PT', { weekday: 'short' }))?.close;
                if (open && close) {
                    toast(`Para as datas selecionadas os hários disponíveis estão compreendidos entre as ${open} e as ${close}`, {
                        duration: 5000,
                        icon: <IoIosWarning size={70} />,

                    });
                }
            }
        }
    }, [startDate, endDate]);
    // Convert schedule days to day numbers
    const scheduleDays = schedule.map(s => dayNameToNumber(s.day));

    const selectStartDate = () => {
        return (
            <div className=' flex flex-col items-center'>
                <p className='font-garetBold text-[grey] text-[.9rem]'>Entrada</p>
                <div
                    className='flex gap-2 w-full justify-center items-center cursor-pointer'

                >
                    <div className='flex flex-row'>
                        <DatePicker
                            className='w-0 h-0'
                            selected={startDate}
                            onChange={(date: Date) => { setStartDate(date); setIsOpenStart(false) }}
                            open={isOpenStart}
                            onCalendarClose={() => setIsOpenStart(false)}
                            onCalendarOpen={() => setIsOpenStart(true)}
                            minDate={today}
                            filterDate={(date) => scheduleDays.includes(date.getDay())}

                        />
                        <div className="flex flex-col">
                            <p className='text-[.8rem] flex items-center text-center' onClick={() => setIsOpenStart(!isOpenStart)}>
                                {startDate.toLocaleDateString('pt-PT', { weekday: 'short', day: 'numeric', month: 'long' })}
                            </p>
                            <input className="h-full focus:outline-none bg-transparent text-[.8rem] " type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const selectEndDate = () => {
        return (
            <div className=' flex flex-col items-center '>
                <p className='font-garetBold text-[grey] text-[.9rem]'>Saída</p>
                <div
                    className='flex gap-2 w-full justify-center items-center cursor-pointer'

                >
                    <div className='flex flex-row'>
                        <DatePicker
                            className='w-0 h-0'
                            selected={endDate}
                            onChange={(date: Date) => { setEndDate(date); setIsOpenEnd(false) }}
                            open={isOpenEnd}
                            onCalendarClose={() => setIsOpenEnd(false)}
                            onCalendarOpen={() => setIsOpenEnd(true)}
                            minDate={today}
                            filterDate={(date) => scheduleDays.includes(date.getDay())} // Only allow days in schedule

                        />
                        <div className="flex flex-col">
                            <p className='text-[.8rem] flex items-center text-center' onClick={() => setIsOpenEnd(!isOpenEnd)}>
                                {endDate.toLocaleDateString('pt-PT', { weekday: 'short', day: 'numeric', month: 'long' })}
                            </p>
                            <input className="h-full focus:outline-none bg-transparent text-[.8rem] " type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }



    return (
        <div className=" rounded-lg shadow-2xl p-4">
            <Toaster />
            <h2 className="text-lg font-semibold text-zinc-800 pb-2">Detalhes da reserva</h2>
            <div className="border border-zinc-400 rounded-2xl px-2 py-4">
                <div className="flex justify-between items-center pb-2">
                    <div>
                        <h3 className="text-zinc-700 font-bold">Passe ilimitado</h3>
                        <p className="text-zinc-500">ENTRADA E SAÍDA LIMITADAS</p>
                    </div>
                    <div className="text-right">
                        <span className="text-3xl font-bold text-red-500">{garage ? getPrice(garage) : 0} €</span>
                        {getDifferenceInDays(startDate, endDate) >= 0 && <p className="text-zinc-400 text-sm">
                            Este produto é de {getDifferenceInDays(startDate, endDate) + 1} dia
                        </p> }                   
                    </div>
                </div>
                <hr className="w-full border-t border-zinc-400" />
                <div className="pt-4">
                    <div className="flex justify-between pb-2">
                        <div className="w-1/2">
                            {selectStartDate()}
                        </div>
                        <div className="w-1/2">
                            {selectEndDate()}
                        </div>
                    </div>
                    <hr className="w-full border-t border-zinc-400" />
                    <div className="pt-4 grid grid-cols-1 seis:grid-cols-2 gap-2">
                        <div>
                            <p className="text-zinc-600">Veículo</p>
                            <p className="font-semibold text-zinc-800">{vehicleType}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-2">
                <div onClick={() =>
                        window.scrollTo({
                            top: refTo.current?.offsetTop,
                            behavior: "smooth"
                        })
                    } className="flex items-center py-2 px-2 bg-zinc-300 rounded-2xl justify-center cursor-pointer">
                    <TbInfoCircleFilled title="Info" size={24} color="black" />
                    <p className="pl-2">Cancelamento gratuito</p>
                </div>
                <div className="pt-4">
                    <button onClick={() => navigate(`/checkout/${garage.name}`)} className="w-full bg-red-500 text-white p-3 rounded-2xl">Continuar</button>
                </div>
            </div>
        </div>
    );
}


export default PreviewCard;