import React from 'react';

interface InputProps {
  label: string;
  type?: "password" | "text" | "email" ;
  value: string | number;
  setValue: (value: any) => void;
  error?: string;
}

export default function Input({ label, type = 'text', value, setValue , error}: InputProps) {
  return (
    <div className="w-full flex flex-col gap-2">
      <div className=' flex items-center gap-4'>

      <p className="font-garet text-balance pl-4">{label}</p>
     {error && <span className=' text-red-800 text-xs'>*{error}</span>}
      </div>
      <input
        type={type}
        placeholder=""
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="bg-[var(--white)] px-4 py-2 rounded-full outline-[var(--primary)]"
      />
      
    </div>
  );
}
