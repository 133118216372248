

import { TbDisabled } from "react-icons/tb";
import { BsCameraVideoFill } from "react-icons/bs";
import { MdVpnKey } from "react-icons/md";
import { RiCarWashingFill } from "react-icons/ri";
import { BiRevision } from "react-icons/bi";
import { LuPaintbrush } from "react-icons/lu";
import { FaTachographDigital } from "react-icons/fa6";
import { MdOutlineCarRental } from "react-icons/md";
import { FcInspection } from "react-icons/fc";
import { TbHours24 } from "react-icons/tb";


const ServiceIcon = ({ serviceName }: { serviceName: string }) => {
        // icon mapping for the services

    const serviceIconMap: { [key: string]: JSX.Element } = {
        "Deficientes": <TbDisabled title="Deficientes" className="px-1 py-1 rounded-full bg-zinc-400" size={28} />,
        "24h aberto": <TbHours24 title="24h aberto" className="px-1 py-1 rounded-full bg-zinc-400" size={28} />,
        "Câmeras": <BsCameraVideoFill title="Câmeras" className="px-1 py-1 rounded-full bg-zinc-400" size={28} />,
        "Chave Física": <MdVpnKey title="Chave Física" className="px-1 py-1 rounded-full bg-zinc-400" size={28} />,
        "Digital Access": <FaTachographDigital title="Digital Access" className="px-1 py-1 rounded-full bg-zinc-400" size={28} />,
        "Pintura": <LuPaintbrush title="Pintura" className="px-1 py-1 rounded-full bg-zinc-400" size={28} />,
        "Rent": <MdOutlineCarRental title="Rent" className="px-1 py-1 rounded-full bg-zinc-400" size={28} />,
        "Lavagens": <RiCarWashingFill title="Lavagens" className="px-1 py-1 rounded-full bg-zinc-400" size={28} />,
        "IPO": <FcInspection title="IPO" className="px-1 py-1 rounded-full bg-zinc-400" size={28} />,
        "Revisão": <BiRevision title="Revisão" className="px-1 py-1 rounded-full bg-zinc-400" size={28} />,
    };

    return serviceIconMap[serviceName] || null;
};

export default ServiceIcon;