
import Form from './components/form';
import Benefits from './components/benefits';
import LevelUp from './components/levelUp';

export default function AddProperty() {

    return (
        <div className='w-full pt-[5rem] px-[8vw]'>
            <Form />
            <Benefits />
            <LevelUp />
        </div>
    );
}

