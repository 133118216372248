import React, { CSSProperties, useState } from 'react';

import Light from '../../../content/images/icons/service-3.png';
import Charger from '../../../content/images/icons/charger.png';
import ArrowLeft from '../../../content/images/icons/arrow-left.png';
import ArrowRight from '../../../content/images/icons/arrow-right.png';
import Input from '../../../components/input';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

export default function LevelUp() {

    const [section, setSection] = useState(1);
    const [type, setType] = useState(1);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [nif, setNif] = useState('');

    async function submit() {
        if (!name || !email || !contact || !nif)
            return alert('Preencha todos os campos.');
        const collectionRef = collection(db, 'partners');
        const docRef = doc(collectionRef, '');
        try {
            await updateDoc(docRef, {});
        } catch (error) {
            console.error('Error updating document:', error);
        }
    }

    const getTransformStyle = () => {
        const translateX = -(section - 1) * 100;
        return { transform: `translateX(${translateX}vw)` };
    };

    const sectionStyle: CSSProperties = {
        width: `calc(100vw - 0vw)`,
        padding: `0 8vw`,
        boxSizing: 'border-box',
        flexShrink: 0,
    };

    return (
        <div className='relative w-full flex flex-col gap-20 overflow-hidden pb-[5rem]'>
            <h3 className='font-garetBold text-[1.2rem] quatro:text-[1.5rem] oito:text-[2rem] text-center uppercase'>Level up your garage</h3>
            <div className='flex will-change-transform mx-[-8vw] transition-all duration-500' style={getTransformStyle()}>
                <div className='w-full' style={sectionStyle}>
                    <div className='flex justify-center gap-[8vw] mb-14'>
                        <div className='flex items-center'>
                            <img src={Light} alt="icon" className='w-[5rem]' />
                            <div className='flex flex-col'>
                                <p className="font-garetBold quatro:text-[1.5rem]">300+</p>
                                <p>Eletric Stations</p>
                            </div>
                        </div>
                        <div>
                            <img src={Charger} alt="icon" className='w-[7rem]' />
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <button onClick={() => setSection(2)} className='bg-[var(--primary)] rounded-full px-8 py-3 text-white'>Get Started &ensp; →</button>
                    </div>
                </div>
                <div className="w-full flex justify-center" style={sectionStyle}>
                    <div className='min-w-[60%] flex flex-col gap-10'>
                        <p>Choose one type:</p>
                        <div className='flex oito:flex-row flex-col gap-6 seis:gap-[3vw]'>
                            <button onClick={() => { setType(1); setSection(3) }} className={`w-full oito:w-[10rem] mil:w-[12rem] h-auto py-8 oito:h-[15rem] rounded-[20px] flex flex-col gap-4 justify-center items-center ${type === 1 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)]'}`}>
                                <p className='font-garetBold'>30 KWa/H</p>
                                <p className='font-garetBold'>Até 1 carro</p>
                            </button>
                            <button onClick={() => { setType(2); setSection(3) }} className={`w-full oito:w-[10rem] mil:w-[12rem] h-auto py-8 oito:h-[15rem] rounded-[20px] flex flex-col gap-4 justify-center items-center ${type === 2 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)]'}`}>
                                <p className='font-garetBold'>50 KWa/H</p>
                                <p className='font-garetBold'>Até 2 carros</p>
                            </button>
                            <button onClick={() => { setType(3); setSection(3) }} className={`w-full oito:w-[10rem] mil:w-[12rem] h-auto py-8 oito:h-[15rem] rounded-[20px] flex flex-col gap-4 justify-center items-center ${type === 3 ? 'bg-[var(--primary)] text-white' : 'bg-[var(--white)]'}`}>
                                <p className='font-garetBold'>150 KWa/H</p>
                                <p className='font-garetBold'>Até 4 carros</p>
                            </button>
                            <div className='flex items-center gap-8 justify-center'>
                                <img onClick={() => setSection(1)} src={ArrowLeft} alt="icon" className='w-6 h-6 cursor-pointer' />
                                <img onClick={() => setSection(3)} src={ArrowRight} alt="icon" className='w-6 h-6 cursor-pointer' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-center" style={sectionStyle}>
                    <div className='min-w-[60%] flex flex-col gap-10'>
                        <p>Fill the form and submit:</p>
                        <div className='grid oito:grid-cols-2 grid-cols-1 oito:gap-[7vw] gap-8 mb-10'>
                            <div className='flex flex-col gap-8'>
                                <Input label="Name" value={name} setValue={setName} />
                                <Input label="E-Mail" value={email} setValue={setEmail} />
                            </div>
                            <div className='flex flex-col gap-8'>
                                <Input label="contact (telemóvel)" value={contact} setValue={setContact} />
                                <Input label="NIF/Nº Contribuinte" value={nif} setValue={setNif} />
                            </div>
                        </div>
                        <div className='flex items-center justify-center gap-8'>
                            <img onClick={() => setSection(2)} src={ArrowLeft} alt="icon" className='w-6 h-6 cursor-pointer' />
                            <button onClick={submit} className='bg-[var(--primary)] rounded-[7px] px-4 py-2 text-white'>Get my charger!</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

