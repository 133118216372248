import 'firebase/firestore';

import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB6KyYEmpfCs-riq9Iz2Es2tkESXs41kpI",
  authDomain: "admin-multipark.firebaseapp.com",
  projectId: "admin-multipark",
  storageBucket: "admin-multipark.appspot.com",
  messagingSenderId: "944909921923",
  appId: "1:944909921923:web:ae99e3f3ec10fe709ab5b6",
  measurementId: "G-408MP6G98Z"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
