import React from 'react';
import DatePicker from 'react-datepicker';
import CalendarIcon from '../../../content/images/icons/calendar.png'; // replace with your actual path

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  date: Date;
  setDate: (date: Date) => void;
  time: string;
  setTime: (time: string) => void;
  today: Date;
  start: boolean
};

const SelectDateTime: React.FC<Props> = ({ isOpen, setIsOpen, date, setDate, time, setTime, today, start }) => {
  return (
    <>
      <div className=' flex flex-col items-center'>
        <p className='font-garetBold text-[grey] text-[.9rem]'>{start ? "Chegada" : "Saída"}</p>
        <div className='flex gap-2 w-full justify-center items-center cursor-pointer'>
          <img src={CalendarIcon} onClick={() => setIsOpen(!isOpen)} alt="icon" className='w-5' />
          <div className='flex flex-row'>
            <DatePicker
              className='w-0 h-0'
              selected={date}
              onChange={(date: Date) => { setDate(date); setIsOpen(false) }}
              open={isOpen}
              onCalendarClose={() => setIsOpen(false)}
              onCalendarOpen={() => setIsOpen(true)}
              minDate={today} />
            <p className='text-[.8rem] flex items-center text-center' onClick={() => setIsOpen(!isOpen)}>
              {date.toLocaleDateString('pt-PT', { weekday: 'short', day: 'numeric', month: 'long' })}
            </p>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center mil:pr-2 mil:border-r border-[grey] '>
        <p className=' text-[grey] text-[.9rem] pr-4'>Hora</p>
        <div className='flex gap-2 w-full justify-center items-center cursor-pointer'>
          <input className="cursor-pointer h-full focus:outline-none bg-transparent text-[.8rem] " type="time" value={time} onChange={(e) => setTime(e.target.value)} />
        </div>
      </div>
    </>
  );
};

export default SelectDateTime;