import { useEffect,  useState } from "react";
import { Link,  useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import CheckOutCard from "./CheckOutCard";
import toast, { Toaster } from "react-hot-toast";
import { TbInfoCircleFilled } from "react-icons/tb";
import { useVehicleContext } from "../../contexts/VehicleContext";
import { loadStripe } from '@stripe/stripe-js';
import { IoCard } from "react-icons/io5";
import axios from "axios";
import Loading from "../../components/loading/loading";
import { useGarages } from "../../contexts/GaragesContext";
import { RiPaypalFill } from "react-icons/ri";
import { PhoneInput } from "react-international-phone";

import 'react-international-phone/style.css';
function Payment() {
    const { garageName } = useParams<{ garageName: string }>();
    const [garage, setGarage] = useState<any>(null);
    const { vehicleType } = useVehicleContext();
    const [isCardChecked, setCardChecked] = useState(false);
    const [isPayPalChecked, setPayPalChecked] = useState(false);
    const [price, setPrice] = useState<Number>(0);

    const [loading, setLoading] = useState(true);
    const garages = useGarages().garages;

    const [booking, setBooking] = useState<any>(null);

    useEffect(() => {
        if (garages.length > 0) {
            setLoading(false);
        }
    }, [garages]);

    useEffect(() => {
        const bookingData = sessionStorage.getItem(`booking${garageName}`);

        if (bookingData) {
            setBooking(JSON.parse(bookingData));
        } 

        const garage = garages.find((garage: { name: string | undefined; }) => garage.name === garageName);
        garageName ? getPrice(garage) : setPrice(0);

        if (garage) {
            setGarage(garage);
        }
    }, [garageName, garages]);


    function getPrice(garage: any) {
        let newGarage = garage;
        if (!newGarage) {
            newGarage = garages.find((garage: { name: string | undefined; }) => garage.name === garageName);
            setGarage(newGarage);
        }
        const prices = newGarage?.price;
        if (prices) {
            const price = prices.find((price: { type: string; value: Number }) => price.type === vehicleType);
            setPrice(price.value);
        }
    }

    const handleCardPayment = async (type: string) => {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

        if (!stripe) {
            return;
        }


        setLoading(true);

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/booking/payment`, {
            payment: {amount : Number(price), currency: "eur", payment_type: type},
            product: {name: garageName, image: garage?.image},
            customer : {email: booking?.email}
        });

        const sessionId = response.data.data.sessionID;
        sessionStorage.setItem(`payment${garageName}`, true.toString());

        const result = await stripe.redirectToCheckout({
            sessionId: sessionId,
        });


        if (result.error) {
            console.log("[error]", result.error);
            toast.error(`Pagamento falhou: ${result.error.message}`);
        }

        setLoading(false);


    };


    const infoCheck = () => {
        return (
            <><p className="font-bold text-zinc-700 pb-4">Confirme se as suas informações estão corretas</p>
                <div className="grid grid-cols-1 mil:grid-cols-2 gap-8">
                    <div>
                        <label className="text-sm font-medium text-zinc-500 pl-2">Nome</label>
                        <input
                            disabled
                            type="text"
                            value={booking?.name}
                            placeholder="Nome"
                            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" />
                    </div>
                    <div>
                        <label className="text-sm font-medium text-zinc-500 pl-2">Apelido</label>
                        <input
                            disabled
                            type="text"
                            value={booking?.surname}
                            placeholder="Apelido"
                            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" />
                    </div>
                    <div className="mil:col-span-2">
                        <label className="text-sm font-medium text-zinc-500 pl-2">Email</label>
                        <input
                            disabled
                            type="text"
                            value={booking?.email}
                            placeholder="Email"
                            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" />
                    </div>
                    <div>
                        <label className="text-sm font-medium text-zinc-500 pl-2">Telemóvel</label>
                        <PhoneInput
                            disabled
                            defaultCountry="pt"
                            value={booking?.phone} />
                    </div>
                </div></>

        );
    };


    return (
        <div className='relative pt-[8rem] gap-12 seis:px-[4vw] px-[8vw]'>
            <Toaster />
            <div className="py-10">
                <Link to={`/checkout/${garageName}`}>
                    <div className="flex text-lg font-extrabold w-fit cursor-pointer">
                        <IoArrowBack title="Voltar atrás" size={28} />
                        <p className="flex items-center">Voltar</p>
                    </div>
                </Link>
            </div>

            <div className="py-12 miledois:flex gap-4 min-h-full">
                <div className="w-full miledois:w-[60%]">
                    <div className="p-4">
                        <h2 className="text-2xl font-semibold pb-8">Seleccione o seu método de pagamento</h2>
                        <div className="flex flex-col gap-2">
                            <div onClick={() => { setCardChecked(!isCardChecked); setPayPalChecked(false); }} className={`flex gap-4 cursor-pointer p-4 rounded-xl border-2 border-solid transition-all ease-in-out duration-500 ${isCardChecked ? "border-[var(--primary)]" : "border-zinc-300"}`}>
                                <div className="w-24 flex justify-center">
                                    <IoCard className="transition-all ease-in-out duration-500 " size={50} color={isCardChecked ? "var(--primary)" : "black"} />
                                </div>
                                <label className={`pl-2 flex transition-all ease-in-out duration-500  justify-center items-center text-${isCardChecked ? "[var(--primary)]" : "black"}`}>Cartão</label>
                            </div>
                            <div className={` transition-max-height duration-700 ease-in-out overflow-hidden ${isCardChecked ? 'max-h-screen' : 'max-h-0'}`}>
                                <div className={`grid grid-cols-1 pb-8 p-4 gap-4 rounded-xl border border-solid border-zinc-300`}>
                                    <div className="pt-4">
                                        {infoCheck()}
                                        <div className="flex justify-center py-4">
                                            <button onClick={() => handleCardPayment("card")} className="w-72 bg-red-500 text-white py-3 rounded-lg">Pagar {price.toString()}€</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => { setCardChecked(false); setPayPalChecked(!isPayPalChecked); }} className={`flex gap-4 cursor-pointer p-4 rounded-xl border-2 border-solid transition-all ease-in-out duration-500 ${isPayPalChecked ? "border-[var(--primary)]" : "border-zinc-300"}`}>
                                <div className="w-24 flex justify-center">
                                    <RiPaypalFill className="transition-all ease-in-out duration-500 " size={50} color={isPayPalChecked ? "var(--primary)" : "black"} />
                                </div>
                                <label className={`pl-2 flex transition-all ease-in-out duration-500  justify-center items-center text-${isPayPalChecked ? "[var(--primary)]" : "black"}`}>PayPal</label>
                            </div>
                            <div className={`transition-max-height duration-700 ease-in-out overflow-hidden ${isPayPalChecked ? 'max-h-screen' : 'max-h-0'}`}>
                                <div className={`grid grid-cols-1 pb-8 p-4 gap-4 rounded-xl border border-solid border-zinc-300`}>
                                    <div className="pt-4">
                                        {infoCheck()}
                                        <div className="flex justify-center py-4">
                                            <button onClick={() => handleCardPayment("paypal")} className="w-72 bg-red-500 text-white py-3 rounded-lg">Pagar com PayPal {price.toString()}€</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="w-full pt-10 miledois:pt-0 miledois:w-[40%]">
                    <CheckOutCard garage={garage} />
                </div>
            </div>
            <div className="pb-10 flex flex-col items-center justify-center gap-2">
                <div className="flex text-[var(--primary)]">
                    <TbInfoCircleFilled title="Info" size={20} />
                    <p className="pl-2 text-sm">Cancelamento gratuito</p>
                </div>
            </div>
            <Loading isOpen={loading} />
        </div>
    );
}

export default Payment;
