import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import Parceiros from './pages/partners/Partners';
import AddProperty from './pages/addProperty/AddProperty';
import Construction from './pages/Construction';
import NotFoundPage from './pages/NotFound';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Blog from './pages/blog/Blog';
import Post from './pages/blog/Post';
import Map from './pages/map/Map';
import Terms from './pages/Terms';
import Layout from './components/pageLayout';
import Preview from './pages/preview/Preview';
import { DateTimeProvider } from './contexts/DateTimeContext';
import { VehicleTypeProvider } from './contexts/VehicleContext';
import CheckOut from './pages/checkout/CheckOut';
import Payment from './pages/checkout/Payment';
import { UserProvider } from './contexts/userContext';
import { GaragesProvider } from './contexts/GaragesContext';
import PaymentSuccess from './pages/checkout/PaymentSuccessfull';
import PaymentFailure from './pages/checkout/PaymentCancel';

function App() {

	return (
		<div className=''>
			<UserProvider>
				<DateTimeProvider>
					<VehicleTypeProvider>
					<GaragesProvider>
						<BrowserRouter>
							<Routes>
								<Route index element={<Layout><Home /></Layout>} />
								<Route path="/add-property" element={<Layout><AddProperty /></Layout>} />
								<Route path="/parceiros" element={<Layout><Parceiros /></Layout>} />
								<Route path="/support" element={<Layout><Contact /></Layout>} />
								<Route path="/construction" element={<Layout><Construction /></Layout>} />
								<Route path="/login" element={<Layout><Login /></Layout>} />
								<Route path="/signup" element={<Layout><Signup /></Layout>} />
								<Route path="/blog" element={<Layout><Blog /></Layout>} />
								<Route path="/post/:titulo" element={<Layout><Post /></Layout>} />
								<Route path="/map" element={<Layout><Map /></Layout>} />
								<Route path="/preview/:garageName" element={<Layout><Preview /></Layout>} />
								<Route path="/checkout/:garageName" element={<Layout><CheckOut /></Layout>} />
								<Route path="checkout/payment/:garageName" element={<Layout><Payment /></Layout>} />
								<Route path="/payment/success/:garageName" element={<PaymentSuccess />} />
								<Route path="/payment/failure/:garageName" element={<PaymentFailure />} />
								<Route path="/checkout/payment/:garageName" element={<Layout><Payment /></Layout>} />
								<Route path="/terms" element={<Layout><Terms /></Layout>} />
								<Route path="*" element={<Layout><NotFoundPage /></Layout>} />
							</Routes>
						</BrowserRouter>
					</GaragesProvider>
				</VehicleTypeProvider>
			</DateTimeProvider>
        </UserProvider>
		</div>
	);
}

export default App;
