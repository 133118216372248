import React, { useEffect, useState } from 'react';
import Input from '../../../components/input';
import UploadIcon from '../../../content/images/icons/upload.png';
import AddCharger from '../../../components/addCharger';
import Modal from '../../../components/modal';
import SimpleSignup, { FormPaper } from './simpleSignup';
import { Player } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import { useUserContext } from '../../../contexts/userContext';
import toast, { Toaster } from 'react-hot-toast';

export default function Form() {
    const { user } = useUserContext()

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [nif, setNif] = useState('');
    const [type, setType] = useState(0);
    const [totalSpots, setTotalSpots] = useState(0);

    const [generalImages, setGeneralImages] = useState<any>([]);
    const [certidaoPredial, setCertidaoPredial] = useState<any>(null);
    const [logo, setLogo] = useState<any>(null);
    const [popup, setPopup] = useState(false);
    const [hasCharger, setHasCharger] = useState(false);
    const [isSignupOpen, setIsSignupOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);


    useEffect(() => {
        if (popup) document.body.classList.add('overflow-hidden');
        else document.body.classList.remove('overflow-hidden');
    }, [popup]);

    const handleFileChange = () => (event: any) => {
        const file = event.target.files[0];
        if (generalImages.length >= 10)
            return alert('Máximo de 10 imagens atingido.');
        if (file && (file.name.endsWith('.png') || file.name.endsWith('.jpg'))) {
            setGeneralImages((prev: any) => [...prev, file]);
        } else {
            alert('Please upload an image with the .png or .jpg format.');
        }
    };

    const handleFileChange2 = () => (event: any) => {
        const file = event.target.files[0];
        if (file && file.name.endsWith('.pdf')) {
            setCertidaoPredial(file);
        } else {
            alert('Please upload a file with the .pdf format.');
        }
    };

    const handleLogoChange = () => (event: any) => {
        const file = event.target.files[0];
        if (file && (file.name.endsWith('.png') || file.name.endsWith('.jpg'))) {
            setLogo(file);
        } else {
            alert('Please upload a file with the .pdf format.');
        }
    };

    function getType() {
        if (type === 0) return '30 KWa/H';
        else if (type === 1) return '50 KWa/H';
        else return '150 KWa/H';
    }

    async function submit() {
        if (!name || !email || !contact || !nif || !totalSpots
            //   || !generalImages || !generalImages.length || !certidaoPredial
        )
        if (isNaN(Number(nif)) || isNaN(Number(contact)) || isNaN(Number(totalSpots))) {
            alert('Os campos NIF, contacto, e totalSpots têm de ser números.');
            return;
        }
        const garageData = {
            name: name,
            email: email,
            phoneNumber: contact,
            NIF: nif,
            totalSpots: Number(totalSpots),
        }

        // formData.append('type', getType());

        // generalImages.forEach((image: string | Blob, index: any) => {
        //   formData.append(`images[${index}]`, image);
        // });

        // formData.append('certidaoPredial', certidaoPredial, certidaoPredial.name);

        // if (logo) {
        //   formData.append('logo', logo, logo.name);
        // }

        try {
            const url = `${process.env.REACT_APP_API_URL}/api/v1/garage/create`
            console.log(user?.token)
            const response = await axios.post(url, garageData, {
                headers: {
                    'Authorization': user!.token
                }
            });
            console.log(response.data);
            if (response.data.condition !== 'success') {
                throw new Error(response.data.error);
            }
            toast.success('Garagem criada com sucesso!');
        } catch (error: any) {
            console.error('Error updating document:', error);
            throw new Error(error)
        }
        
    }
    return (
        <div className='flex flex-col mil:px-[7vw] pb-[5rem] oito:pb-[10rem] gap-16'>
            <Toaster></Toaster>
          <h1 className='text-[1.2rem] quatro:text-[2rem] text-center'>Crie sua primeira garagem!</h1>
            <div className='grid oito:grid-cols-2 grid-cols-1 oito:gap-[7vw] gap-8 mb-[5rem]'>
                <div className='flex flex-col gap-8'>
                    <Input label="Name" value={name} setValue={setName} />
                    <Input label="E-Mail" value={email} setValue={setEmail} />
                    <Input label="Número de vagas na garagem" value={totalSpots} setValue={setTotalSpots} />
                    <Input label="contact (telemóvel)" value={contact} setValue={setContact} />
                    <Input label="NIF/Nº Contribuinte" value={nif} setValue={setNif} />
                </div>
                <div className='flex flex-col gap-8'>
                    <p className='uppercase font-garetBold'>Documentos necessários:</p>
                    <ul className='flex flex-col gap-8 list-disc mb-[2rem]'>
                        <li className='flex quatro:flex-row flex-col gap-8 items-center'>
                            <div className='flex flex-col gap-3 w-full quatro:w-[20rem]'>
                                <p className='uppercase font-garetBold'>Imagens gerais</p>
                                <p className='uppercase'>Máximo de 10 (formato PNG ou JPG)</p>
                            </div>
                            <div className='relative w-[10rem] py-3 text-white rounded-[7px] bg-[var(--primary)] flex justify-center items-center gap-2'>
                                <input type="file" onChange={(event) => handleFileChange()(event)} className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer' />
                                <button className='flex items-center gap-4'>UPLOAD <img src={UploadIcon} alt="icon" className='w-6' /></button>
                            </div>
                        </li>
                        <li className='flex gap-8 quatro:flex-row flex-col items-center'>
                            <div className='flex flex-col gap-3 w-full quatro:w-[20rem]'>
                                <p className='uppercase font-garetBold'>Certidão predial da garagem</p>
                                <p className='uppercase'>(formato PDF - max 300Mb)</p>
                            </div>
                            <div className='relative w-[10rem] py-3 text-white rounded-[7px] bg-[var(--primary)] flex justify-center items-center gap-2'>
                                <input type="file" onChange={(event) => handleFileChange2()(event)} className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer' />
                                <button className='flex items-center gap-4'>UPLOAD <img src={UploadIcon} alt="icon" className='w-6' /></button>
                            </div>
                        </li>
                    </ul>
                    <div className='flex flex-col gap-8'>
                        <p className='font-garetBold uppercase'>Opcional:</p>
                        <div className="flex gap-4 items-center">
                            <p className='uppercase font-garetBold w-[10rem]'>Logotipo da empresa/pessoa</p>
                            <div className='relative w-[10rem] text-white rounded-full h-[2.5rem] flex justify-center items-center cursor-pointer'>
                                <input type="file" onChange={(event) => handleLogoChange()(event)} className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer' />
                                <button className='w-10 h-10 text-white rounded-[7px] bg-[var(--primary)] flex justify-center items-center gap-2'><img src={UploadIcon} alt="icon" className='w-6' /></button>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <div className='flex items-center gap-3'>
                            <p className='uppercase font-garetBold'>Já tenho carregador elétrico</p>
                            <div onClick={() => setHasCharger(!hasCharger)} className={`w-6 h-6 border-black border-2 rounded-[5px] cursor-pointer ${hasCharger ? 'bg-[var(--primary)]' : 'bg-[var(--white)]'}`}></div>
                        </div>
                        {
                            type === 0 && !popup ?
                                <button onClick={() => setPopup(true)} className='font-garetBold text-white bg-[var(--primary)] w-[13rem] flex items-center justify-center gap-4 py-1 rounded-full'>ADD Charger <span className='text-[1.5rem]'>+</span></button>
                                :
                                <div className='flex flex-col gap-2'>
                                    <button onClick={() => setPopup(true)} className='font-garetBold text-white bg-[var(--green)] w-[13rem] flex items-center justify-center gap-4 py-3 rounded-full'>Charger Added</button>
                                    <button onClick={() => setType(0)} className='font-garetBold  border-black border-2 w-[13rem] flex items-center justify-center gap-4 py-3 rounded-full hover:bg-[var(--red)] hover:text-white'>{type === 1 ? '30 KWa/H' : type === 2 ? '50 KWa/H' : '150 KWa/H'}</button>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className='w-full flex flex-col items-center mb-[5rem]'>
                <button onClick={() => setIsSignupOpen(true)} className='p-2 bg-[var(--primary)] text-white rounded-[7px] mb-10'>Continuar</button>

                <p className='font-garetBold text-center w-full oito:w-1/2'>IRÁ RECEBER UM E-MAIL COM O CONTRATO E AS INFORMAÇÕES DE ACESSO À SUA DASHBOARD ONDE PODE CONTROLAR: DINHEIRO, RESERVAS E EDIÇÕES</p>
            </div>
            <p className='uppercase text-[1.2rem] oito:text-[1.5rem]'><span className='font-garetBold'>Nota:</span> Qualquer questão envie para <a href="mailto:partners@multipark.pt" className='font-garetBold hover:underline'>partners@multipark.pt</a></p>
            {popup && <AddCharger setPopup={setPopup} type={type} setType={setType} />}
            <Modal isOpen={isSignupOpen} onClose={() => setIsSignupOpen(false)} >

                <SimpleSignup next={submit} lastStep={<FormPaper title={
                    <h1 className='text-[1.2rem] quatro:text-[2rem] text-center'>Bem-vindo à <span className='font-garetBold uppercase'>Multipark</span></h1>
                }>


                    <Player
                        autoplay
                        keepLastFrame
                        src={`${process.env.PUBLIC_URL}/animations/congrets.json`}
                        style={{ height: '200px', width: '200px' }}
                    >
                    </Player>

                    <p className="text-gray-700 mb-4 text-center">
                        <span className='font-garetBold uppercase'>Você concluiu o cadastro da sua primeira garagem.</span> A verificação da garagem pode levar até 5 dias úteis.
                    </p>
                    <button onClick={() => setActiveStep(activeStep + 1)} className=' rounded-full bg-[var(--primary)] px-4 py-2 text-white'>Acessar minha conta</button>


                </FormPaper>} />
            </Modal>
        </div>
    );
}

