import React from 'react';
// import Star from '../../content/images/icons/full-star.png';
// import HalfStar from '../../content/images/icons/half-star.png';
import ServiceIcon from '../../components/ServiceIconMapper';
import { useNavigate } from "react-router-dom";

export default function MapCard({ ...props }) {
    const navigate = useNavigate();

    return (
        <div onClick={() => props?.setSelectedPark(props?.park)} onDoubleClick={() => navigate(`/preview/${props?.park?.name}`)} className={`flex rounded-[20px] w-full h-[14rem] ${props?.selectedPark?.name === props?.park?.name ? 'border-[var(--primary)]' : 'border-black'} bg-[var(--white)] hover:bg-[white] border-[3px] cursor-pointer p-3 gap-4`}>
            <div className='flex flex-col gap-2 items-center w-[10rem]'>
                <img src={props?.park?.image} alt="img" className='rounded-[15px] w-full aspect-square object-cover' />
                {/* TODO reviews no a data type in garages, implement later <div className="flex gap-1">
                    <div className="w-4 h-4"><img src={Star} alt="icon" className='w-full h-full' /></div>
                    <div className="w-4 h-4"><img src={Star} alt="icon" className='w-full h-full' /></div>
                    <div className="w-4 h-4"><img src={Star} alt="icon" className='w-full h-full' /></div>
                    <div className="w-4 h-4"><img src={Star} alt="icon" className='w-full h-full' /></div>
                    <div className="w-4 h-4"><img src={HalfStar} alt="icon" className='w-full h-full' /></div>
                </div>
                <p className='text-[.7rem]'>{props?.park?.reviews} Opiniões</p> */}
            </div>
            <div className='w-full flex flex-col gap-2'>
                <div className='w-full flex flex-col gap-2 h-[7rem] border-b-2 border-b-black pb-2'>
                    <p className='uppercase font-garetBold text-[1.2rem]'>{props?.park?.name}</p>
                    <div className='w-full flex flex-col gap-2 quatro:pl-4 text-[.9rem] pt-2 overflow-auto'>
                        <div className='w-90 flex justify-between flex-col'>
                            {props?.park?.price ?
                                props?.park?.price.map((price: { type: any; value: any; }, index: React.Key | null | undefined) => {
                                    return (
                                        <p key={index} className="relative font-garetBold text-0.7rem quatro:text-1.2rem top-[-6px]">{`${price.type} - ${price.value}€`}</p>
                                    )
                                })
                                :
                                <p className="relative font-garetBold text-0.9rem quatro:text-1.2rem top-[-6px]">Sem preço definido</p>
                            }
                        </div>
                    </div>
                </div>
                <div className='flex gap-2 h-full items-center pl-4'>
                    {props?.park?.services ?
                        props?.park?.services.map((service: string, index: React.Key | null | undefined) => {
                            return (
                                <ServiceIcon key={index} serviceName={service} />
                            )
                        })
                        :
                        <p className="relative font-garetBold text-0.9rem quatro:text-1.2rem top-[-6px]">Sem serviços ativos</p>
                    }
                </div>
            </div>
        </div>
    );
}

