export default function Select({ ...props }) {
    return (
        <div className="w-full flex flex-col gap-2">
            <p className='font-gare text-balance pl-4'>{props.label}</p>
            <select
                value={props.value}
                onChange={(e) => props.setValue(e.target.value)}
                className='bg-[var(--white)] px-4 py-2 rounded-full outline-[var(--primary)]'
            >
                <option value="">Selecionar</option>
                {
                    props?.list.length !== 0 ? props?.list.map((ele: string, index: number) => {
                        return (
                            <option key={index} className='bg-[var(--white)] px-6 py-2 rounded-full outline-[var(--primary)]'>
                                {ele}
                            </option>
                        )
                    }) :
                        <p className='text-center w-full'>Nenhum resultado</p>
                }
            </select>
        </div>
    )
}