import React from 'react';
import ListImage from '../../../content/images/home/list.jpg';
import List2 from '../../../content/images/home/users.jpg';
import Smile from '../../../content/images/icons/smile.png';
import Stock from '../../../content/images/icons/stock.png';

export default function List({ texts }: any) {
    return (
        <div className='w-full flex flex-col gap-8 mil:gap-[6rem]'>
            <div className='w-full flex mil:justify-center'>
                <h3 className='font-garetBold text-[1.7rem] quatro:text-[2rem] seis:text-[2.5rem] text-balance seis:w-[40rem] mil:text-center'>{texts?.title}</h3>
            </div>
            <div className='w-full flex mil:flex-row flex-col justify-between gap-[2rem] seis:gap-4'>
                <div className='w-full flex flex-col gap-8 seis:gap-[4rem]'>
                    <p className='w-full seis:w-[85%] text-[1rem]'>{texts?.text}</p>
                    <div className='w-full flex flex-col mil:flex-col seis:flex-row-reverse justify-end gap-8 seis:items-center mil:items-start'>
                        <button onClick={() => window.location.href = "/add-property"} className='w-[12rem] h-[3rem] font-garetBold text-white rounded-full px-6 py-3 bg-[var(--primary)] text-center'>List now</button>
                        <div className='flex items-center gap-2'>
                            <img src={List2} alt="list" className='w-[7rem] seis:w-[10rem]' />
                            <div className='flex flex-col'>
                                <p className='text-[1.2rem] font-garetBold'>1000+</p>
                                <p className=''>Property owners</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='relative w-full flex justify-center items-center'>
                    <img src={ListImage} alt="list" className='object-cover w-[30rem] z-[1]' />
                    <div className='absolute top-4 quatro:top-[4rem] right-0 seis:right-[2vw] w-[10rem] quatro:w-[12rem] py-2 bg-[var(--white)] z-[2] border-black border-2 rounded-full flex justify-between items-center pl-3 pr-1'>
                        <p className='font-garetBold text-[.8rem] quatro:text-[1rem]'>MONTHLY CASH</p>
                        <img src={Smile} alt="icon" className='absolute top-1/2 translate-y-[-50%] right-0 w-8' />
                    </div>
                    <div className='absolute bottom-[3rem] left-0 seis:left-[3rem] w-[10rem] quatro:w-[12rem] py-2 bg-[var(--white)] z-[2] border-black border-2 rounded-full flex justify-between items-center pl-3 pr-1'>
                        <p className='font-garetBold text-[.8rem] quatro:text-[1rem]'>70+ GROWTH</p>
                        <img src={Stock} alt="icon" className='absolute top-1/2 translate-y-[-50%] right-2 w-6' />
                    </div>
                </div>
            </div>
        </div>
    );
}

