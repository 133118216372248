import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export default function Blog() {

    const [lang, setLang] = useState("pt");
    const [posts, setPosts] = useState<any>(null);

    useEffect(() => {
        const lang = localStorage.getItem("lang");
        if (lang) {
            setLang(lang);
        }
    }, []);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/posts/${process.env.REACT_APP_BLOG_USER}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (!response.ok) {
                    throw new Error(`Error: ${response.status}`);
                }
                const posts = await response.json();
                setPosts(posts);
            } catch (error) {
                console.error('Fetching user posts failed:', error);
                return null;
            }
        }
        fetchData();
    }, []);

    if (!posts) {
        return (
            <div className='relative w-full min-h-screen flex justify-center items-center'>
                <CircularProgress color="inherit" />
            </div>
        )
    }

    return (
        <div className='pb-[10rem] pt-[5rem]'>
            <div className='w-full bg-white flex flex-col justify-between items-center py-[3rem]'>
                <h1 className='text-[3rem] text-[var(--primary)] font-garetBold'>Blog</h1>
            </div>
            <div className='relative py-[5rem] w-full min-h-screen flex flex-col gap-[5rem] px-[8vw] bg-[var(--primary)] rounded-[25px]'>
                <h3 className='text-left font-bold text-[2rem] text-white'>Os nossos artigos</h3>
                <div className='flex flex-col justify-center items-center gap-10 oito:grid oito:grid-cols-2 milecem:grid-cols-3'>
                    {
                        posts.length !== 0 ? posts.map((ele: any, index: number) => {
                            return (
                                <div key={index} className='flex flex-col w-[20rem] h-[20rem] bg-white rounded-[25px] p-6 justify-between place-self-center'>
                                    <div className='flex flex-col gap-4'>
                                        <h3 className='font-bold text-[1.2rem]'>{ele?.titulo}</h3>
                                        <p>{ele?.descricao}</p>
                                    </div>
                                    <div className='flex w-full justify-center'>
                                        <button onClick={() => window.location.href = `/post/${ele?.titulo}`} className='bg-[var(--primary)] w-[8rem] py-1 rounded-full text-white'>{lang === 'pt' ? 'ver mais' : 'see more'}</button>
                                    </div>
                                </div>
                            )
                        }) :
                            <p className='text-center w-full'>Nenhum resultado</p>
                    }
                    {
                        posts.length % 3 !== 0 &&
                        <div className='w-[20rem] h-[20rem] bg-white rounded-[25px] justify-center items-center flex place-self-center'>
                            comming soon
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

