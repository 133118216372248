import { Close } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  margin?: number/*rem*/; 
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, margin = 8}) => {
  const [isVisible, setIsVisible] = useState(isOpen);
    const duration = 500
  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), duration); // Tempo de animação deve corresponder ao CSS
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const modalClasses = `fixed top-0 left-0  w-full max-h-screen overflow-hidden bg-gray-500/75 flex justify-center items-center transition-opacity duration-${duration} ease-in-out ${
    isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
  } z-50`;

  return (
    <>
      {isVisible && (
        <div className={modalClasses}>
          <div className={`bg-white rounded-2xl w-full h-full m-60 `}>
            <div className={`absolute `}>
            <IconButton  onClick={onClose}>
            <Close />
          </IconButton> 
            </div>
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
