import { useState } from 'react';
import Logo from '../content/images/logos/logo.png';
import VisionD from '../content/images/logos/visiond.png';
import Flag from '../content/images/icons/pt.png';

export default function Footer() {

    const [email, setEmail] = useState('');

    function submitEmail() {
        if (!email) return alert('Insira o seu email.');
        // TODO: save email & send email with confirmation
    }

    return (
        <div className='w-full bg-[var(--primary)]'>
            <div className='w-full h-full bg-white rounded-t-[45px] px-[8vw] pt-10'>
                <div className='flex mil:flex-row flex-col justify-between gap-[4rem] mil:gap-4'>
                    <div className='w-full seis:w-[30rem] mil:w-[20rem] flex flex-col gap-[3rem]'>
                        <img src={Logo} alt="logo" className='w-[12rem] pt-8' />
                        <div>
                            <p>Stay in the loop and sign up for the Wardiere newsletter:</p>
                            <div className='relative w-full flex pl-4 h-[3rem] py-[.2rem] border-2 border-black rounded-full mt-4'>
                                <input
                                    type="text"
                                    placeholder='Enter your email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className='outline-none w-[90%] pr-4'
                                />
                                <div onClick={submitEmail} className='relative mr-[.2rem] flex justify-center items-center bg-black rounded-full h-full aspect-square text-white cursor-pointer'>→</div>
                            </div>
                        </div>
                        <div className='w-[10rem] border-2 border-black rounded-full h-[2rem] flex gap-4 items-center px-2'>
                            <img src={Flag} alt="icon" className='w-5' />
                            <p>Português</p>
                        </div>
                    </div>
                    <div className='grid grid-cols-2 seis:grid-cols-3 miledois:gap-[4rem] gap-[2rem]'>
                        <div className='flex flex-col gap-2'>
                            <p className='font-garetBold mb-2'>Company</p>
                            <a href="/" className='hover:underline'>Home</a>
                            <a href="/about" className='hover:underline'>About</a>
                            <a href="/solutions" className='hover:underline'>Solutions</a>
                            <a href="/pricing" className='hover:underline'>Pricing</a>
                            <a href="/team" className='hover:underline'>Team</a>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p className='font-garetBold mb-2'>Users</p>
                            <a href="/add-property" className='hover:underline'>List a property</a>
                            <a href="/rent" className='hover:underline'>Rent a car</a>
                            <a href="/driver" className='hover:underline'>Become a driver</a>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p className='font-garetBold mb-2'>Documentation</p>
                            <a href="/support" className='hover:underline'>Help center</a>
                            <a href="/support" className='hover:underline'>Contact</a>
                            <a href="/support" className='hover:underline'>FAQ</a>
                            <a href="/pp" className='hover:underline'>Privacy Policy</a>
                            <a href="/terms" className='hover:underline seis:hidden'>Terms & Conditions</a>
                        </div>
                    </div>
                </div>
                <div className='relative w-full h-[6rem] flex justify-between mt-8 py-2 border-t-[1px] border-[grey]'>
                    <p className='text-center w-full seis:text-left'>&copy; Multipark Lda. 2023</p>
                    <div onClick={() => window.open('https://visiond.pt', '_blank')} className='w-full mil:relative absolute bottom-1 left-1/2 translate-x-[-50%] mil:-translate-x-0 mil:left-auto mil:bottom-auto items-end justify-center mil:items-center h-full flex cursor-pointer gap-2'>
                        <p>Powered by</p>
                        <img src={VisionD} alt="visiond" className='w-[7rem] oito:w-[8rem] invert' />
                    </div>
                    <a href='/terms' className='w-full hover:underline hidden seis:flex justify-end'>Terms & Conditions</a>
                </div>
            </div>
        </div>
    );
}

