import React, { createContext, useState, Dispatch, SetStateAction, useContext } from 'react';

interface DateTimeContextType {
  startDate: Date;
  setStartDate: Dispatch<SetStateAction<Date>>;
  endDate: Date;
  setEndDate: Dispatch<SetStateAction<Date>>;
  startTime: string;
  setStartTime: Dispatch<SetStateAction<string>>;
  endTime: string;
  setEndTime: Dispatch<SetStateAction<string>>;
}

const today = new Date();

const DateTimeContext = createContext<DateTimeContextType | null>(null);

export const DateTimeProvider = ({ children }: { children: React.ReactNode }) => {
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [startTime, setStartTime] = useState('09:00');
    const [endTime, setEndTime] = useState('10:00');

    return (
        <DateTimeContext.Provider value={{ startDate, setStartDate, endDate, setEndDate, startTime, setStartTime, endTime, setEndTime }}>
            {children}
        </DateTimeContext.Provider>
    );
};

export function useDateTimeContext() {
  const context = useContext(DateTimeContext);
  if (!context) {
    throw new Error('DateTimeContext is null, component must be used inside a DateTimeProvider');
  }
  return context;
}

export default DateTimeContext;